import React, {useState, useEffect, useRef, useContext, useCallback} from 'react';
import '../css/cover.css';
import { Navbar, Nav, Button, Form, Container, Row, Col, Card, Image } from 'react-bootstrap';
import {useNavigate} from 'react-router-dom';
import logo from '../assets/logo.png';
import image001 from '../assets/image001.jpg';
import image003 from '../assets/websiteImage.png';
import brownBg from '../assets/brown-background.jpg';
import {TransparentNavBar, NavBar, BlackNavBar} from '../components/NavBar';
import {Footer} from '../components/Footer';

export const OurStory = () => {
	const navigate = useNavigate();

	return 	<div>
		<div style={{backgroundColor:'black',paddingLeft:20,paddingRight:20}}>
	      {/* Transparent Navbar */}
	      <BlackNavBar loggedIn={false}/>
	      <div className="cover-image-container d-flex align-items-center justify-content-center" style={{ height: '100vh', overflow: 'hidden' }}>
	        <img
	          src="cover-image.jpg"
	          alt="Cover"
	          style={{position: 'absolute', width: '100%', height: '100%', objectFit: 'cover',top: '0', left: '0', zIndex:-1 }}
	        />
	        <Container style={{borderColor:'red',borderWidth:2}} className="d-flex align-items-center justify-content-center">
				<Card style={{borderWidth:0,backgroundColor:'transparent'}}>	
					<Row>
						<Col xs={12} md={6}>
							<Container>
						        <h2 style={{color:'#fed700'}}>Our Story</h2>
						        <h2 style={{color:'#0AC5BF'}}>SHAP - Sexual Health and Pleasure</h2>
						        <p style={{color:'white', textAlign:'justify'}} >“Sexual health is the art of balancing sexual safety and responsibility with the lifelong curiosity of pleasure, exploring sexual interests, and remaining curious about the ever-changing sources of sexual pleasure.” The Harvey Institute.</p>
						        <Button className="gradient-border-button" onClick={()=>navigate("/signup")} style={{borderWidth:0, backgroundColor:'transparent', color:'#fed700',padding:'15px 40px 15px 40px'}}>
						          Join Now
						        </Button>
						    </Container>
						</Col>
					</Row>
				</Card>
		    </Container>
	      </div>
	  </div>
	  <Container>
	  	<Card style={{ width: '100%', marginTop:20, marginBottom:20, borderColor:'transparent' }}>
	      <Card.Body>
	        <Card.Title style={{color:'#0AC5BF', fontWeight:'bold'}}>Our Objectives</Card.Title>
	        <Card.Text>
	          <p style={{textAlign:'justify'}}>The World Health Organization (WHO) defines sexual health as a state of physical, emotional, mental, and social well-being in relation to sexuality. It’s not merely the absence of disease, dysfunction, or infirmity.</p>
	          <p style={{textAlign:'justify'}}>Sexual health requires a positive and respectful approach to sexuality and sexual relationships, as well as the possibility of having pleasurable and safe sexual experiences free of coercion, discrimination, and violence.</p>
	        </Card.Text>
	        <Card.Text style={{fontWeight:'bold'}}>Shap stemmed from the recognition of the need to:</Card.Text>
	       	<ul>
	       		<li>Break down the stigma surrounding discussions about sexuality</li>
	       		<li>Embrace and celebrate pleasure in all its forms, extending far beyond the confines of sex (penetrative sex culminating in orgasms) and substances.</li>
	       		<li>Explore diverse desires (sexual and non-sexual) in a shame-free, judgment-free, and no-pressure environment</li>
	       		<li>Create safer and more inclusive spaces in Africa where adults can connect with themselves and like-minded individuals</li>
	       	</ul>
	       	<Card.Text style={{fontWeight:'bold'}}>Our goal is to promote sexual health and :</Card.Text>
	       	<ul>
	       		<li>Offering them a safe, inclusive, and empowering space where they can gather to explore and experiment with their sexuality and sexual relationships, and for pleasurable experiences</li>
	       		<li>Encouraging them to embrace the six principles of <p className="p-0 m-0" style={{color:'#0AC5BF',cursor:"pointer"}}>sexual health</p> : Consent, Non-exploitation, Protection from STIs and unintended pregnancy, Honesty, Shared values, and Mutual Pleasure</li>
	       	</ul>
	       	<div className="d-flex align-items-center justify-content-center">
	       		<Button style={{backgroundColor:'#000', color:'#fed700', paddingBottom:5, paddingTop:5, paddingLeft:20, paddingRight:20, borderColor:'#fed700', borderRadius:20, borderWidth:3, alignSelf:'center'}}>More about Our Space</Button>
	      	</div>
	      </Card.Body>
	    </Card>
	  </Container>
	  <div style={{backgroundColor:'black', paddingTop:25, paddingBottom:25}}>
	  	<Container className="d-flex" style={{backgroundColor:'transparent'}}>
		  	<Card style={{backgroundColor:'transparent', marginTop:20, marginBottom:20,borderColor:'transparent'}}>
			  	<Card.Title style={{color:'white',marginBottom:15,fontWeight:'bold'}}>Our work will be greatly inspired by <a href="https://thepleasureproject.org" style={{textDecoration:'none',color:'#0AC5BF'}}>pleasure principles.</a></Card.Title>
			    <Card.Img onClick={() => {window.location.href = 'https://thepleasureproject.org'}} src={image001} style={{maxHeight:500}}/>
			</Card>
		</Container>
	  </div>
	  <Container>
	  	<Card style={{ width: '100%', marginTop:20, marginBottom:20, borderColor:'transparent' }}>
	      <Card.Body>
	        <Card.Title style={{color:'#0AC5BF',fontWeight:'bold'}}>Our Social Club</Card.Title>
	        <Card.Text>
	          <p style={{textAlign:'justify'}}>Shap is a private members’ social club where we prioritize approaching and interacting with other members first and foremost as fellow human beings. We consciously set aside any preconceived notions or stereotypes, choosing instead to see each individual as a complex and unique person deserving of respect and understanding.</p>
	          <p style={{textAlign:'justify'}}>By focusing on our shared humanity and treating each other with empathy and kindness, we create a space where everyone can feel comfortable being themselves. Let’s engage in conversations that are free from judgment and full of curiosity and openness, seeking to learn from each other’s diverse perspectives and experiences.</p>
	          <p style={{textAlign:'justify'}}>In valuing each other as humans first, we can cultivate a sense of belonging and acceptance within our social club, where differences are celebrated and embraced as opportunities for growth and connection. Together, let’s build a social club where mutual respect and genuine human connection are at the core of all our interactions.</p>
	        </Card.Text>
	      </Card.Body>
	    </Card>
	  </Container>
	  <div style={{backgroundColor:'black'}}>
		  <Container className="mb-0" style={{backgroundColor:'black', paddingTop:40}}>
		  	<Card style={{backgroundColor:'black', width: '100%', marginTop:20, marginBottom:0, borderWidth:0}}>
			  	<Row style={{color:'#0AC5BF',fontWeight:'bold',marginBottom:10}}>
			  		<h2 style={{fontWeight:'bold',color:'#fed700'}}>Our Values</h2>
			  	</Row>
			  	<Row xs={1} md={1} lg={2} xxl={3} className="align-items-stretch">
			        <Col style={{borderWidth:1,borderColor:'#888',marginTop:10}} className="d-flex">
			          <Card>
			          	<Card.Body>
				          	<Card.Title style={{color:'#0AC5BF',fontWeight:'bold'}}>Consent, Communication & Consideration</Card.Title>
				          	<Card.Text>
					        	<p style={{textAlign:'justify'}}>Consent and open, honest communication form the foundation of any interaction. Let’s prioritize Consent using the FRIES model: Freely given, Reversible, Informed, Enthusiastic, and Specific. Consent reflects a person’s willingness and ability to engage in a specific act. It involves the mutual creation of an agreement that aims for “YES!” rather than just the absence of “No.”Let’s foster a culture of openness, honesty, and transparency in our interactions, empowering every member to freely express their needs, desires, thoughts, and feelings. This includes setting boundaries and communicating expectations without hesitation. Being considerate entails showing attentiveness, kindness, and respect for each other’s needs, desires, boundaries, and preferences, thereby cultivating an environment of support and thoughtfulness.</p>
					        </Card.Text>
					    </Card.Body>
			          </Card>
			        </Col>
			        <Col style={{borderWidth:1,borderColor:'#888',marginTop:10}} className="d-flex">
			          <Card>
			          	<Card.Body>
				          	<Card.Title style={{color:'#0AC5BF',fontWeight:'bold'}}>Look out for each other</Card.Title>
				          	<Card.Text>
					        	<p>Let’s proactively look out for each other’s well-being and safety, cultivating a supportive environment where every member feels cared for and valued. This dedication not only builds trust and security but also enables everyone to fully enjoy their experiences, fostering a close-knit social club where we consistently support one another.Our social club actively promotes bystander intervention and cultivates a culture of accountability, where members hold each other responsible for their actions and intervene if they witness any behavior that could endanger someone’s safety or well-being. This also entails promptly reporting any suspicious or inappropriate conduct to maintain the ongoing safety and comfort of all members.</p>
					        </Card.Text>
					    </Card.Body>
			          </Card>
			        </Col>
			        <Col style={{borderWidth:1,borderColor:'#888',marginTop:10}} className="d-flex">
			          <Card>
			          	<Card.Body>
				          	<Card.Title style={{color:'#0AC5BF',fontWeight:'bold'}}>Open Minded</Card.Title>
				          	<Card.Text>
				          		<p>We celebrate diversity and nurture a non-judgmental atmosphere where everyone is embraced and welcomed. Our dedication to open-mindedness and acceptance encompasses all facets of sexual orientation, gender identity, relationship structures or statuses, desires, lifestyles, and interests. We profoundly respect the intrinsic worth of every individual, regardless of differences, and actively confront assumptions and stereotypes about sexuality and relationships.Through fostering inclusivity, we establish a safe space where individuals can genuinely express themselves without fear of discrimination, prejudice, or harm.</p>
						    </Card.Text>
					    </Card.Body>
			          </Card>
			        </Col>
			        <Col style={{borderWidth:1,borderColor:'#888',marginTop:10}} className="d-flex">
			          <Card>
			          	<Card.Body>
				          	<Card.Title style={{color:'#0AC5BF',fontWeight:'bold'}}>Safety</Card.Title>
				          	<Card.Text>
					        	<p style={{textAlign:'justify'}}>Safety is a top priority in our social club. While we prioritize looking out for each other’s safety and well-being, we also stress the importance of personal responsibility. It’s crucial for individuals to feel safe within themselves, deeply understanding the reasons behind their actions and ensuring that they originate from a positive place. Being honest with oneself is crucial and an essential step in ensuring safety. We encourage members to be proactive in managing risks by taking precautions and exercising caution, especially when meeting new people. We urge members to meet in public places and to honestly understand and communicate their needs, desires, thoughts, feelings, and expectations. Trusting instincts and exercising good judgment are also important, empowering members to respond appropriately to any signs of discomfort or suspicion.</p>
					        </Card.Text>
					    </Card.Body>
			          </Card>
			        </Col>
			        <Col style={{borderWidth:1,borderColor:'#888',marginTop:10}} className="d-flex">
			          <Card>
			          	<Card.Body>
				          	<Card.Title style={{color:'#0AC5BF',fontWeight:'bold'}}>Empathy</Card.Title>
				          	<Card.Text>
					        	<p>Empathy serves as a cornerstone value within our social club. We uphold the belief in treating each individual with kindness, understanding, consideration, and respect, valuing their feelings and experiences. Within our empathetic social club, we encourage genuine interactions and provide support to members through all stages of their journey.By nurturing empathy, we create a space where individuals can authentically express themselves. This atmosphere of acceptance fosters a sense of safety for members to be vulnerable, knowing they will receive understanding and compassion in return.Through embracing empathy, we cultivate a culture of support and solidarity, ensuring everyone feels valued and heard.</p>
					        </Card.Text>
					    </Card.Body>
			          </Card>
			        </Col>
			        <Col style={{borderWidth:1,borderColor:'#888',marginTop:10}} className="d-flex">
			          <Card>
			          	<Card.Body>
				          	<Card.Title style={{color:'#0AC5BF',fontWeight:'bold'}}>Respect</Card.Title>
				          	<Card.Text>
					        	<p style={{textAlign:'justify'}}>We require all members to partake in respectful conversations, treating each other with dignity, and consistently embracing consent, in line with our core belief in treating every individual with kindness, consideration, and respect, irrespective of differences or disagreements.Our social club fosters a culture of respecting personal boundaries, autonomy, and agency, urging members to honor each other’s choices and preferences without judgment or coercion. Maintaining respect for one another’s boundaries, values, and opinions is essential for upholding a safer and empowering environment. We actively confront harmful attitudes and behaviors while advocating for a culture of inclusivity, acceptance, and affirmation.</p>
					        </Card.Text>
					    </Card.Body>
			          </Card>
			        </Col>
			        <Col style={{borderWidth:1,borderColor:'#888',marginTop:10}} className="d-flex">
			          <Card>
			          	<Card.Body>
				          	<Card.Title style={{color:'#0AC5BF',fontWeight:'bold'}}>Authentic</Card.Title>
				          	<Card.Text>
					        	<p style={{textAlign:'justify'}}>At our core, we are committed to fostering an environment where members can freely express themselves without holding back. We firmly believe that authenticity is not only liberating but also crucial for personal growth and fulfillment. When we embrace our true selves, we thrive, finding contentment in our actions, choices, and relationships. Living authentically involves embracing ourselves truthfully, acknowledging all the diverse facets that make us unique individuals. By nurturing a culture of authenticity, we provide room for members to delve into their identities, share their truths, and forge meaningful connections grounded in genuine acceptance and understanding.</p>
					        </Card.Text>
					    </Card.Body>
			          </Card>
			        </Col>
			        <Col style={{borderWidth:1,borderColor:'#888',marginTop:10}} className="d-flex">
			          <Card>
			          	<Card.Body>
				          	<Card.Title style={{color:'#0AC5BF',fontWeight:'bold'}}>Feedback</Card.Title>
				          	<Card.Text>
					        	<p style={{textAlign:'justify'}}>Within our social club, we hold a deep appreciation for the power of feedback in fostering personal and collective growth. We value both positive affirmations and constructive criticism, as they provide invaluable insights that propel us forward. Let’s actively engage in offering suggestions, sharing our perspectives, and giving genuine praise for each other’s strengths and contributions. By cultivating a culture of open communication and receptivity, we create a supportive space where positive feedback uplifts and motivates us, while constructive criticism guides us towards further refinement. Let’s embrace the opportunity to both give and receive feedback graciously, knowing that through this exchange, we collectively empower each other to reach new heights of excellence within ourselves and our social club.</p>
					        </Card.Text>
					    </Card.Body>
			          </Card>
			        </Col>
			        <Col style={{borderWidth:1,borderColor:'#888',marginTop:10}} className="d-flex">
			          <Card>
			          	<Card.Body>
				          	<Card.Title style={{color:'#0AC5BF',fontWeight:'bold'}}>Fun</Card.Title>
				          	<Card.Text>
					        	<p style={{textAlign:'justify'}}>Pleasure and enjoyment are central components of overall well-being. Our social club is dedicated to organizing a diverse array of social events and activities designed to foster laughter, joy, exploration, and playfulness. From light meetups to themed parties, there will always be something exciting happening at SHAP! We encourage members to embrace their spontaneity and sense of adventure, letting go of inhibitions and fully immersing themselves in their experiences. Whether you’re seeking quality leisure time, private group activities, or intimate gatherings, there’s something for everyone to enjoy here. Let’s come together to create unforgettable memories, build lasting friendships, and celebrate the sheer delight of having fun in a safe and supportive environment. Come join us as we embark on new adventures and make the most of every moment!</p>
					        </Card.Text>
					    </Card.Body>
			          </Card>
			        </Col>
			    </Row>
			    <Row className="d-flex align-items-center justify-content-center" style={{paddingTop:20, paddingBottom:20}}>
			    	<div className="d-flex align-items-center justify-content-center">
			    		<Button onClick={() => navigate('/signup')} className="valuesBtn" style={{width:'100%',maxWidth:500,backgroundColor:'#000', color:'#fed700', borderColor:'#fed700', borderRadius:20, borderWidth:3, alignSelf:'center'}}>Our values align, join us today!</Button>
			    	</div>
			    </Row>
			</Card>
		  </Container>
		</div>
      <Footer/>
    </div>
}