import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import {useNavigate} from 'react-router-dom';
import '../css/bottomToolbar.css'

export const BottomToolbar = () => {
  const navigate = useNavigate();
  return (
    <Navbar className="d-md-none" fixed="bottom" bg="dark" variant="dark">
      <Nav className="mx-auto">
        <Nav.Link class="tabLink" onClick={() => navigate('/app/discover')}><div style={{display:'flex',alignItems:'center',justifyContent:'center'}}><i className="bi bi-house"></i></div><div class="tabbarText">Discover</div></Nav.Link>
        <Nav.Link class="tabLink" onClick={() => navigate('/app/buzz')}><div style={{display:'flex',alignItems:'center',justifyContent:'center'}}><i class="bi bi-list-columns-reverse"></i></div><div class="tabbarText">Buzz</div></Nav.Link>
        <Nav.Link class="tabLink" onClick={() => navigate('/app/groups')}><div style={{display:'flex',alignItems:'center',justifyContent:'center'}}><i className="bi bi-people"></i></div><div class="tabbarText">Groups</div></Nav.Link>
        <Nav.Link class="tabLink" onClick={() => navigate('/app/forums')}><div style={{display:'flex',alignItems:'center',justifyContent:'center'}}><i class="bi bi-chat-square-quote"></i></div><div class="tabbarText">Forums</div></Nav.Link>
        <Nav.Link class="tabLink" onClick={() => navigate('/app/profile')}><div style={{display:'flex',alignItems:'center',justifyContent:'center'}}><i className="bi bi-person"></i></div><div class="tabbarText">Profile</div></Nav.Link>
      </Nav>
    </Navbar>
  );
}
