import React, {useState, useEffect, useRef, useContext, useCallback} from 'react'
import { Container, Row, Col } from 'react-bootstrap';

export const Guidelines = () => {

	return  <Container className="mt-4">
            <Row>
              <Col>
                <div className="text-justify">
  				        <h2>Interaction Guidelines</h2>
                  <p>
                    Welcome to our adult social club, where we embrace the profound wisdom of Audre Lorde’s words: “The erotic is a measure between the beginnings of our sense of self and the chaos of
                    our strongest feelings. It is an internal sense of satisfaction to which, once we have experienced it, we know we can aspire. The erotic is not a question only of what we do; it is a question
                    of how acutely and fully we can feel in the doing.”
                  </p>
                  <p>
                    As W.H. Auden eloquently put it, “To be a complete human being, to fully inhabit your own vitality, is to live undivided within your own nature. No part of us is more habitually exiled,
                    caged, and crushed under the weight of millennia of cultural baggage than Eros—the part that includes sexuality but transcends it to also include our capacity for spontaneity and
                    playfulness.”
                  </p>
                  <p>
                    The erotic provides the power that comes from sharing deeply any pursuit with another person. The sharing of joy—whether physical, emotional, psychic, or intellectual—forms a bridge
                    between the sharers, creating a foundation for understanding and reducing the threat of differences. Another essential function of the erotic connection is to openly and fearlessly underline
                    our capacity for joy.
                  </p>
                  <p>
                    We have been raised to fear the “yes” within ourselves, our deepest cravings. But once recognized, those that do not enhance our future lose their power and can be altered. The fear of our
                    desires keeps them suspect and indiscriminately powerful, for to suppress any truth is to give it strength beyond endurance. The fear that we cannot grow beyond whatever distortions we
                    may find within ourselves keeps us docile, loyal, and obedient, leading us to accept many facets of our oppression.
                  </p>
                  <p>
                    When we begin to live from within outward, in touch with the power of the erotic within ourselves, and allow that power to inform and illuminate our actions upon the world around us,
                    we start to be responsible to ourselves in the deepest sense. By recognizing our deepest feelings, we begin to reject suffering and self-negation, moving away from the numbness that often
                    seems like the only alternative in our society.
                  </p>
                  <p>
                    Sharing the power of each other's feelings is different from using another's feelings as we would use a Kleenex. When we look away from our experience, erotic or otherwise, we use
                    rather than share the feelings of those who participate with us. Use without the consent of the used is abuse. When we ignore ourselves while satisfying our erotic needs with others, we
                    use each other as objects of satisfaction instead of sharing joy and making connections based on our similarities and differences. To refuse to be conscious of what we are feeling at any
                    time, however comfortable that might seem, is to deny a large part of the experience and allow ourselves to be reduced to the abused and the absurd.
                  </p>
                  <p>
                    Embracing Audre Lorde’s insights on the erotic calls us to a deeper understanding of ourselves and our interactions within this adult social club. Lorde reminds us that the erotic is not
                    merely about actions but about the depth of feeling and satisfaction we experience in our pursuits. It transcends mere sexuality, encompassing spontaneity, playfulness, and the capacity for
                    joy.
                  </p>
                  <p>
                    As we embark on this journey together, guided by Lorde’s words and the sentiments echoed by W.H. Auden, it becomes clear that the erotic is not just about individual fulfillment but also
                    about shared connection. It forms bridges between us, allowing for understanding and appreciation of our differences.
                  </p>
                  <p>
                    Shame, by its very nature, compels us to hide from others and even from ourselves. Brené Brown explains that “shame derives its power from being unspeakable,” meaning that shame loses
                    its power when shared. When we speak about our shame, we start to dismantle its hold over us.
                  </p>
                  <p>
                    Similarly, our society often instills a fear of our deepest desires, compelling us to suppress them. This fear can make our desires appear suspicious and overly potent because hiding any truth
                    amplifies its strength. However, when we acknowledge and explore these desires, we can discern which ones benefit us and which ones do not.
                  </p>
                  <p>
                    By embracing the opportunity to explore your desires with an open mind, adaptability, and flexibility, you create a path toward pleasure, joy, personal growth and self-discovery.
                    Acknowledging these desires allows for a happier, healthier, more balanced life, where you can retain what enhances your well-being and release what hinders it.
                  </p>
                  <p>
                    Crucially, we must approach our interactions with intention, awareness and consent, understanding that using others for our own satisfaction is a form of abuse. Instead, we must strive to
                    share in each other's joy and connection, recognizing and respecting the feelings and experiences of all involved.
                  </p>
                  <p>
                    As we navigate this space together, let us honor the wisdom of Lorde and Auden, cultivating a culture of mutual respect, authenticity, and empowerment. In doing so, we not only enrich
                    our own lives but contribute to creating a social club founded on genuine connection and shared humanity.
                  </p>
                  <p>
                    <strong>Membership Expectations</strong>
                  </p>
                  <ul>
                    <li>
                      Suppose the expression of nudity, alternative sexualities or sexual content of any nature offends you, or you believe that there is a potential for you to be offended. In that case, we
                      kindly ask that you refrain from registering and instead respectfully leave this site.
                    </li>
                    <li>
                      Given that we still live in a society where sex is often taboo, we need to be mindful of this reality as we pursue our mission. Let’s work together to safeguard our social club and ensure
                      that everyone feels respected and supported in their journey towards sexual health and pleasure.
                    </li>
                    <li>
                      These interaction guidelines should be observed in conjunction with our Terms of Service. If you notice any member violating these guidelines, please report them to us promptly.
                      We cannot keep our social club safe if we are unaware of issues, so please let us know.
                    </li>
                  </ul>
                  <p>
                    <strong>Consequences of Violation</strong>
                  </p>
                  <p>
                    We take the violation of our interaction guidelines seriously. Depending on the severity of the infraction and the member's past behavior, consequences may range from account
                    termination to legal action.
                  </p>
                  <p>
                    <strong>Behavioral Principles</strong>
                  </p>
                  <p>
                    At Shap, we prioritize providing a non-judgmental, and pressure-free environment where members can freely be themselves without fear of discrimination, prejudice, or harm. We
                    encourage members to be respectful and mindful of each other as they interact and use our Service.
                  </p>
                  <p>
                    We need to consider the motivations, feelings and thought processes behind our behavior. It's only when we can turn the mirror to ourselves and dig deep into understanding ourselves
                    can we fully show up.
                  </p>
                  <p>
                    We adhere to the six principles of sexual health: Consent, Non-exploitation, Protection from STIs and unintended pregnancy, Honesty, Shared values and Mutual Pleasure.
                  </p>
                  <p>
                    <strong>Guiding Principles</strong>
                  </p>
                  <p>
                    Our interactions will be guided by BRAVING (an acronym for Building Trust) by Brené Brown.
                  </p>
                  <p>
                    <strong>Boundaries</strong>
                  </p>
                  <p>
                    Setting boundaries means making clear what's okay and what's not okay. You must be clear about your boundaries so others can understand and respect your limits. It's also important to
                    understand the boundaries of others so trust can flow both ways.
                  </p>
                  <p>
                    <strong>Reliability</strong>
                  </p>
                  <p>
                    You do what you say you'll do. This means staying aware of your competencies and limitations, so you don't over promise and can deliver on commitments while balancing competing
                    priorities. Enter and exit your engagements with careful thought and attention to the needs and feelings of everyone concerned. No ghosting (leaving people on read); instead,
                    communicate openly and conclude matters respectfully.
                  </p>
                  <p>
                    <strong>Accountability</strong>
                  </p>
                  <p>
                    You trust people who own up to their mistakes, apologize, and make amends. But others can only do that if you allow them. If you immediately write someone off when they make a
                    mistake or stop talking to them, you don’t give them a chance to step into their accountability. This goes both ways. When you make mistakes, you need the other person to allow you to
                    acknowledge your mistakes, apologize, and make amends.
                  </p>
                  <p>
                    <strong>Vault</strong>
                  </p>
                  <p>
                    You don’t share information or experiences that are not yours to share. You need to know that your confidences are kept while also not sharing with others any information about other
                    people. Confidentiality and privacy must be honored. Keep all information shared within the social club private.
                  </p>
                  <p>
                    <strong>Integrity</strong>
                  </p>
                  <p>
                    Integrity involves choosing courage over comfort and consistently doing what is right, even when it's difficult. Doing the right thing means aiming at a good result for all concerned —
                    and being willing to change course as you learn more about the situation.
                  </p>
                  <p>
                    <strong>Non Judgment</strong>
                  </p>
                  <p>
                    You can ask for what you need, and others can ask for what they need. You can talk about how you feel without judgment. This also includes being comfortable and willing to have open
                    and sometimes uncomfortable conversations. It's important to remember that what feels good for you might not feel good for someone else. Everyone's interests, desires and sources of
                    satisfaction are different. Different is simply different, not better or worse.
                  </p>
                  <p>
                    <strong>Generosity</strong>
                  </p>
                  <p>
                    Extending the most generous interpretation to the intentions, words, and actions of others. This involves approaching situations with empathy, compassion, and understanding and
                    making the kindest assumption about a person's intentions or behaviors, even in ambiguous or challenging circumstances.
                  </p>
                  <p>
                    In addition to this, you shall embody GGG by Dan Savage:
                  </p>
                  <p>
                    <strong>Good</strong> means you put in the work and are present, responsive, communicative and receptive to input.
                  </p>
                  <p>
                    <strong>Gift of pleasure</strong>
                  </p>
                  <p>
                    Give pleasure without the expectation of an immediate return. Find joy in the happiness of others. We are creating a social club of generous, skilled givers and enthusiastic, delighted
                    receivers.
                  </p>
                  <p>
                    <strong>Game for anything within reason</strong>
                  </p>
                  <p>
                    Be willing to explore things that do not immediately turn you on or are new to you, or you are neutral about. But don’t do things that you know turn you off. Know your soft and hard
                    limits and express them. Respect and uphold other people's limits. If something is not a solid YES or a solid NO, examining your uncertainty and considering what you can learn from it is
                    healthy. Are you feeling shame? Is it hard to ask for? Or perhaps you need to do a little more homework! No matter what, there’s a lot of magic in the maybes - explore your maybes and
                    see where that takes you.
                  </p>
                  <p>
                    <strong>Interaction Guide</strong>
                  </p>
                  <ol>
                    <li>
                      Fill out your profile when you are relaxed and feeling good about yourself. Be as specific and accurate as possible, ensuring the information you share is upfront and straightforward.
                      Avoid concealing any details that could influence someone’s decision. Let’s be honest and clear about our intentions, so we can give others the opportunity to make informed
                      decisions.
                    </li>
                    <li>
                      Read through members’ profiles carefully before requesting to connect. Only send connection requests to members who match your interests, needs, and desires.
                    </li>
                    <li>
                      Prioritize approaching and interacting with other members first and foremost as fellow human beings. Approach each interaction without internal biases, preconceived notions, and
                      stereotypes.
                    </li>
                    <li>
                      If you do not want to receive connection requests and would like to make the first move, ensure you fill in that profile field.
                    </li>
                    <li>
                      Pushy and unwelcome advances are not acceptable. Do not send any unsolicited messages of a sexual nature, and use SFW language until you have consent to use NSFW language.
                      Do not repeatedly contact someone who has expressed that they no longer want to communicate with you.
                    </li>
                    <li>
                      If someone isn’t interested in you or if you're not interested in another person, a polite ‘no thank you’ is sufficient and should be respected. Rejection is part of the process and should
                      be expected.
                    </li>
                    <li>We recommend:</li>
                    <ol>
                      <li>Keep your interaction on-site, with the exception of your interactions with Mod, where personal identifying details may be shared.</li>
                      <li>Meet people you are interested in at our meetups first, or actively engage within our online groups or forums so you get a chance to gauge their general vibe.</li>
                      <li>Meet in reputable establishments. Use your own form of transportation to and from your meet up.</li>
                      <li>For those engaging in sexual activity, practice pre-care and aftercare. Discuss with all involved and agree on what you are going to do, your limits, safewords, and care plan.</li>
                      <li>Log your dates. Share the details of where you're going and who you're meeting with us via Mod, and we will check in with you.
                      </li>
                    </ol>
                    <li>
                      Do no harm to yourself or others. It is important to understand the difference between hurt and harm: hurt is temporary and often done for pleasure, while harm is lasting and done out
                      of malice or as a means of coping. You may personally give another person explicit prior permission to hurt you. To hurt someone is to cause pain that goes away on its own. Hurt might
                      need extra attention, like a band-aid, an ice pack, or some comforting snuggles, but it doesn't require professional services. To harm someone is to cause pain that does not go away on its
                      own. Harm needs professional assistance before it can heal.
                    </li>
                    <li>
                      If someone makes you uncomfortable, for any reason, let them know. If they persist, block and report them to us.
                    </li>
                    <li>
                      Do not yuck on anyone's yum. Do not judge others for how they choose to express and enjoy themselves. Desires are varied and exploration takes many shapes. We are all wired
                      differently to give, receive, and experience pleasure.
                    </li>
                    <li>
                      We encourage you to explore, but everyone has the right to do so at their own pace. Never pressure, force, coerce, or manipulate anyone to engage in anything.
                    </li>
                    <li>
                      Pleasure is the measure. It’s whether or not you like the activity you are engaging in, whether it’s genuinely pleasurable to you. Play is typified by spontaneity, joyfulness, and
                      inhibition and is done not as a means to an end but for its inherent pleasure.
                    </li>
                    <li>
                      When participating in pleasurable activities, be present and mindful. Being present-focused, intentional and nonjudgmental of ourselves and others can lead to improved experiences of
                      pleasure and joy.
                    </li>
                    <li>
                      Sex is learned behavior. While sexual instinct may be built-in, it does not come with built-in knowledge. Sexual drive might be innate, but sexual information, sexual ethics, and sexual
                      behavior are learned. Learning about sex can help you and your partners find more pleasure, comfort, and confidence. Informed consent requires a discussion of the risks involved in the
                      activity and the steps needed to reduce those risks, including study, training, technique, and practice. Be a life-long learner!
                    </li>
                    <li>
                      Fantasy is different from reality. Be realistic and manage your expectations.
                    </li>
                    <li>
                      Consent (see best practices for consent) is absolutely essential. Authentic consent emphasizes the sincerity and genuineness of the consent provided. It extends beyond mere agreement
                      and enthusiasm, ensuring that consent is given willingly, without any external or internal pressures, manipulation, or coercion.
                    </li>
                    <li>
                      Be responsible and disclose your STI status to potential partners. Practicing consent entails being honest about any potential risks you may pose. If you receive this information, it's
                      crucial not to respond negatively. STIs are common, and stigmatizing them is unwarranted. It's important to get tested regularly and practice safer sex.
                    </li>
                    <li>
                      Members’ lives outside of our social club are off-limits unless you have been explicitly invited into these parts of their lives. Do not “out” anyone as kinky, non-monogamous, or any
                      form of alt-sex to anyone. Do not use or share anyone's Personally Identifiable Information in any form or format without their consent.
                    </li>
                    <li>
                      Conflict resolution should be approached with empathy, patience, and understanding. You will strive to resolve conflicts in a respectful manner.
                    </li>
                    <li>
                      The profile fields are designed to facilitate your interactions rather than serve as strict labels. We encourage you to explore and experiment with different aspects. Your identity,
                      sexuality, and desires are fluid and multifaceted, so feel free to embrace new experiences and aspects of yourself as you evolve.
                    </li>
                  </ol>
                    
                  <p>
                    <strong>Media</strong>
                  </p>
                  <p>
                    You must not post images or videos that:
                  </p>
                  <ul>
                    <li>Glorify violence.</li>
                    <li>Contain any form of contact information.</li>
                    <li>Display minors (children) in any photograph or video. A minor is defined as anyone appearing to be under the age of 18 years.</li>
                    <li>
                      Feature close-up pictures solely of genitalia. Tasteful nude poses are acceptable only within NSFW zones and on your profile (excluding the profile picture). Other explicit media must be
                      confined to NSFW zones exclusively. If you choose to post nudes, ensure your face and other identifying features, such as tattoos or marks, are covered.
                    </li>
                  </ul>
                  <p>
                    <strong>Account safety</strong>
                  </p>
                  <ul>
                    <li>You must always operate your account safely and securely.</li>
                    <li>
                      Get a new email account specifically for use on our website. It may be preferable to use the same email provider to avoid any apparent changes. If this isn’t a concern, consider using
                      Protonmail.
                    </li>
                    <li>
                      Choose a username that you've never used elsewhere. Avoid any references to your name or nickname that could potentially trace back to you. Remember, usernames may be used in
                      real-life interactions, so choose wisely. Once a username is selected, it cannot be altered.
                    </li>
                  </ul>
                  <p>
                    * Never reuse passwords or use a password from another service. Change your password frequently.
                  </p>
                  <p>
                    * Consider using the Brave browser, which offers an option to lock private tabs. If this isn’t feasible, ensure to regularly delete all temporary files, cookies, history, forms, and passwords
                    after each session. Though it may be tedious, it’s crucial.
                  </p>
                  <p>
                    * Never share your login details or allow anyone else access to your account. Avoid any actions that may compromise the security of your account.
                  </p>
                  <p><strong>Financial Safety</strong></p>
                  <p>
                    You must refrain from sending money or disclosing your financial information to any individual you've communicated with or met through our Service, even if they claim to be facing an
                    emergency. If anyone attempts to solicit money or acquire your financial details, please report them to us immediately.
                  </p>
                  <p><strong>Solicitation</strong></p>
                  <p>
                    Solicitation or involvement in prostitution of any kind is strictly prohibited. There shall be no exchange of money or gifts between members for sex, play, companionship, or any other
                    interaction.
                  </p>
                  <p><strong>Reporting abuse or violation of our Guidelines</strong></p>
                  <p>
                    You may report any abuse or violation of our Guidelines by emailing report@shap.buzz or utilizing the report button on someone's profile.
                  </p>
                  <p><strong>Disclaimer</strong></p>
                  <p>
                    This website provides and hosts information intended to be correct and up-to-date, though it is not guaranteed. Shap does not assume responsibility for actions or non-actions taken by
                    individuals who visit the site, and no one shall be entitled to claim detrimental reliance on any information provided or expressed.
                  </p>
                  <p>
                    Our Guidelines are continuously evolving, and we value your suggestions. You can share your ideas with us at suggestions@shap.buzz.
                  </p>
                  <p>
                    We strive to cultivate a friendly environment where everyone feels welcomed and included. We entrust you with contributing not only to an inviting and friendly atmosphere but also to
                    a space where we can explore and express ourselves safely and discreetly.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
}

export const Terms = () => {
  return (
    <Container className="pt-4">
      <Row>
        <Col>
          <div className="text-justify">
		  	<h2>
				Terms of Service
			</h2>
            <p>
				By your ues of the website and sevices, you confirm your acceptance of these terms and conditions.
			</p>
            <p style={{fontWeight:'bold'}}>
				Definition
			</p>
            <p>
				In this Agreement, the following terms shall have the following meanings:
			</p>
            <p><b>"Account":</b> means collectively the personal information, payment information and credentials used by Members to access Paid Content, Member Content, Member Profiles and/or any System on the Website.</p>
            <p><b>"Content":</b> means any text, graphics, images, audio, video, software, data compilations and any other form of information capable of being store in a computer that appears on or forms part of this website.</p>
            <p><b>"Member Content":</b> means any content submitted to the website by members including, but not limited to their member profile, photos, communications, groups and forums.</p>
            <p><b>"Membership fee":</b> means the sum of money paid by Members at per month to keep their account active and enable them to be Member as detailed on the website.</p>
            <p><b>"Membership period":</b> means the period for which a Membership has been purchased.</p>
            <p><b>"Member Profile":</b> means the profile created by a Member on the Website.</p>
            <p><b>"Paid Content":</b> means Content accessible only upon the creation of an Account and the payment of a Membership Fees.</p>
            <p><b>"Service":</b> means the Shap social club providing Members a Website either now or in the future.</p>
            <p><b>"System":</b> means any online communications infrastructure that Shap makes available through the website either now or in the future. This includes, but is not limited to, web-based email; forums, live chat facilities and email links.</p>
            <p><b>"User/Members/You":</b> means any third party or Member that accesses the Website.</p>
            <p><b>"Website":</b> means the Website that your are currently using shap.buzz</p>
            <p><b>"We/Us/Our":</b> means Milu Tilleli Limited trading as Shap.</p>
            <p>
				<b>Account</b>
			</p>
            <p>
				1. An Account is require to use parts of Our Website.
			</p>
            <p>
				2. You will need an Account to view the Paid Content, Member Profile and be a Member.
			</p>
            <p>
				3. You may not create an Account if you are under 25 years of age. If We discover or have any reason to suspect that your are not over 25 years of age. If we discover or have any reason to suspect that you are not over 25 years of age , then We reserse Our right to suspend or terminate your membership and terminate your Account immediately adn without notice. To attend our events you are still required to be 25 years or older.
			</p>
            <p>
				4. When creating an Account, the information you provide must be accurate, truthful and complete. If any of your information changes at a later date, it is your responsibility to ensure that your Account is kept up-to-date.
			</p>
            <p>
				5. You must not share your account with anyone else. If you believe your Account is being used without your permission, please contact Us immediately. We will not be liable for any unauthorised use of your Account.
			</p>
            <p>
				6. You must not use anyone else's Account.
			</p>
            <p>
				7. You must not share your Account details with anyone.
			</p>
            <p>
				8. If you wish to close and delete your Account. You may do so at any time by email or deleting from your account. Closing your Account will result in the removal of your information from Our System. Once you close your Account you will not be able to access your information or Account details again. If you have an active Membership, your Account will remain active for the duration of the remainder of the Membership Period.
			</p>
            <p>
				<b>Payment</b>
			</p>
            <p>
				1. Payment for Membership will be due at the time of purchase.
			</p>
            <p>
				2. Member subscription payment can be made in USD.
			</p>
            <p>
				<b>Membership Rules and Disclaimers</b>
			</p>
            <p>
				Please note that any Member subscription paid by you is subject to a minimum contractual period of one month.
			</p>
            <p>
              1. You may apply to become a Member of the Website by completing the Registration Form and agreeing to these Terms and Conditions, our Privacy Policy, and Interaction Guidelines.
            </p>
            <p>
              2. The details that You provide and confirm in the Registration Form must be complete and correct, including Your confirmation that You are 25 years or over.
            </p>
            <p>
              3. You will become a Member only if and when You have paid the Membership Fee for Your Membership.
            </p>
            <p>
              4. We reserve the right to cancel, withdraw, or refuse to renew Your Membership, or suspend it for a specific period, if Your conduct in Our reasonable opinion is unacceptable, or is or may be in Our reasonable opinion, harmful to the reputation of Us, the Website, Our Members, or amounts to Your breach of these Terms and Conditions or Our other policies and guidelines, or where in Our reasonable opinion such expulsion, withdrawal, or refusal to renew Your Membership, is otherwise in the interests of the other Members of the Website. For more details, please refer to Our Acceptable Use Policy.
            </p>
            <p>
              5. It is your responsibility to ensure that you are happy with the information and media that you add to the Website when you sign up for an Account and when you create a Member Profile. We recommend that you do not disclose any personal details about yourself such as telephone numbers, home address, or email address to any other Members on our Website. We are not liable for your disclosure of personal information to any Members through Our Website. You are disclosing any such information at your own risk. We are not responsible for any media that you post on the Website.
            </p>
            <p>
              6. We request that you communicate only in English through our Website.
            </p>
            <p>
              7. We request that you treat other Members with respect and dignity.
            </p>
            <p>
              8. We will not be liable for the behavior of you and/or any other Members on our Website and/or at our events.
            </p>
            <p>
              9. Whilst we reserve the right to verify Member details, we do not personally check and verify the identity of Members on the Website.
            </p>
            <p>
              10. We may organize special events for our Members to attend. You must ensure that you are responsible for your own safety at events. We are not responsible for your safety at any events you attend and you attend any such events at your own risk.
            </p>
            <p>
              11. You agree that your Member Profile will be viewed by other Members on the Website.
            </p>
            <p>
              12. You are solely responsible for all communications and interactions with other Members through the Website and outside of the Website.
            </p>
            <p>
              13. We do not endorse or support any Content uploaded or opinions expressed by our Members on the Website.
            </p>

            <h2>Intellectual Property</h2>
            <p>
              1. All Content included on the Website, unless uploaded by Members, including but not limited to, text, graphics, logos, icons, images, sound clips, video clips, data compilations, page layout, underlying code, and software is the property of Shap, or Our affiliates. By continuing to use the Website, you acknowledge that such material is protected by applicable intellectual property and other laws.
            </p>
            <p>
              2. Your use of the Website grants no rights to you in relation to copyright, trademarks, or other of Our intellectual property rights or the intellectual property rights of third parties.
            </p>
            <p>
              3. You may not, without limitation, copy, reproduce, republish, download, post, broadcast, record, transmit, commercially exploit, communicate to the public, or otherwise use the Content included in or provided via the Website.
            </p>
            <p>
              4. You may not monitor, data-mine, or copy Our Website page, any content on the Website, nor collect, archive, trade, or sell any personal data or communications about the Members.
            </p>
            <p>
              5. You must not use any robot, spider, site search or retrieval application, or any other method or device to copy, retrieve, archive, or index any portion of the Website or the Service or the System.
            </p>
            <p>
              6. You must not distribute, display, publish, print in any form electronic or otherwise any Content from the Website, the Service, the System, Member Profiles, the names of any Members, and/or any personal details of Members. You must not share, transmit, or sell any Content on Our Website with anyone who is not a Member.
            </p>

            <h2>Use of Paid Content</h2>
            <p>
              1. Payment of a Membership Fee grants you a license to use all Paid Content on the Website for the duration of your Membership. You may use such Content for personal purposes only.
            </p>
            <p>
              2. Commercial use of Paid Content is not permitted under the terms of a normal Membership.
            </p>
            <p>
              3. Under such a license, you agree that:
            </p>
            <ul>
              <li>you will not use the Paid Content of the Website for commercial purposes; and</li>
              <li>you will not systematically copy Paid Content from the Website with a view to creating or compiling any form of comprehensive collection, compilation, directory, or database.</li>
            </ul>

            <h2>Availability of the Website and Modifications</h2>
            <p>
              1. We accept no liability for any disruption or non-availability of the Website resulting from external causes including, but not limited to, ISP equipment failure, host equipment failure, communications network failure, power failure, natural events, acts of war, or legal restrictions and state-mandated censorship.
            </p>
            <p>
              2. We reserve the right to alter, suspend or discontinue any part (or the whole of) the Website including, but not limited to, the Content and/or Paid Content available. These Terms and Conditions shall continue to apply to any modified version of the Website unless it is expressly stated otherwise.
            </p>

            <h2>Limitation of Liability</h2>
            <p>
              1. To the fullest extent permissible by law, We accept no liability to any user for any loss or damage, whether foreseeable or otherwise, in contract, tort (including negligence), for breach of statutory duty, or otherwise, arising out of or in connection with the use of (or inability to use) the Website or the use of or reliance upon any content included on the Website.
            </p>
            <p>
              2. To the fullest extent permissible by law, We exclude all representations, warranties, and guarantees (whether express or implied) that may apply to the Website or any content included on the Website.
            </p>
            <p>
              3. We exercise all reasonable skill and care to ensure that the Website is free from viruses and other malware. Subject to sub-Clause 17.5, We accept no liability for any loss or damage resulting from a virus or other malware, a distributed denial of service attack, or other harmful material or event that may adversely affect your hardware, software, data, or other material that occurs as a result of your use of the Website or any other site referred to on the Website.
            </p>
            <p>
              4. We neither assume nor accept responsibility or liability arising out of any disruption or non-availability of the Website resulting from external causes including, but not limited to, ISP equipment failure, host equipment failure, communications network failure, natural events, acts of war, or legal restrictions and censorship.
            </p>
            <p>
              5. Nothing in these Terms and Conditions excludes or restricts Our liability for fraud or fraudulent misrepresentation, for death or personal injury resulting from negligence, or for any other forms of liability which cannot be excluded or restricted by law.
            </p>
            <p>
              6. We neither assume nor accept responsibility or liability arising out of the hacking or similar of the Website.
            </p>
            <p>
              7. In the event that any of these terms are found to be unlawful, invalid, or otherwise unenforceable, that term is to be deemed severed from the rest of these Terms and Conditions and shall not affect the validity and enforceability of the remaining terms.
            </p>

            <h2>Advertising</h2>
            <p>
              1. We may feature advertising within the Website.
            </p>
            <p>
              2. You agree that you will not attempt to remove or hide any advertising using HTML/CSS or by any other method.
            </p>
            <p>
              3. We are not responsible for the content of any advertising in the Website. Each advertiser is responsible for the content of their own advertising material. We will not be responsible for any advertising in the Website including, but not limited to, any errors,

 inaccuracies, or omissions.
            </p>

            <h2>Privacy</h2>
            <p>
              1. Use of the Website is also governed by Our <a href="/privacyPolicy">Privacy Policy</a> which is incorporated into these Terms and Conditions by this reference. To view the Privacy Policy, please click on the link above.
            </p>

            <h2>How We Use Your Personal Information (Data Protection)</h2>
            <p>
              1. All personal information that We may collect will be collected, used, and held in accordance with Our <a href="/privacyPolicy">Privacy Policy</a> which is incorporated into these Terms and Conditions by this reference. To view the Privacy Policy, please click on the link above.
            </p>

            <h2>Acceptable Usage Policy</h2>
            <p>
              1. You may only use Our Website in a manner that is lawful and that complies with the provisions of this Clause 13. Specifically:
            </p>
            <ul>
              <li>you must be aged 25 or over to use Our Website;</li>
              <li>you must ensure that you comply fully with any and all local, national or international laws and/or regulations;</li>
              <li>you must not use Our Website in any way, or for any purpose, that is unlawful or fraudulent;</li>
              <li>you must not use Our Website to knowingly transmit data, send, or upload any material that contains any form of virus or other malware, or any other code designed to adversely affect computer hardware, software, or data of any kind; and</li>
              <li>you must not use Our Website in any way, or for any purpose, that is intended to harm any person or persons in any way.</li>
            </ul>
            <p>
              2. When submitting Member Content (or communicating in any other way using Our Website) or using Our Website or System, you must not submit, communicate or otherwise do anything that:
            </p>
            <ul>
              <li>is obscene, deliberately offensive, hateful or otherwise inflammatory;</li>
              <li>promotes violence;</li>
              <li>promotes or assists in any form of unlawful activity;</li>
              <li>discriminates against, or is in any way defamatory of, any person, group, or class of persons, race, gender, religion, nationality, disability, sexual orientation, or age;</li>
              <li>is intended or otherwise likely to threaten, harass, annoy, alarm, inconvenience, upset, or embarrass another person;</li>
              <li>is calculated or is otherwise likely to deceive;</li>
              <li>is intended or otherwise likely to infringe (or threaten to infringe) another person's right to privacy;</li>
              <li>misleadingly impersonates any person or otherwise misrepresents your identity or affiliation in a way that is calculated to deceive (obvious parodies are not included within this definition provided that they do not fall within any of the other provisions of this sub-Clause 12.2);</li>
              <li>implies any form of affiliation with Us where none exists;</li>
              <li>infringes, or assists in the infringement of, the intellectual property rights (including, but not limited to, copyright, patents, trade marks and database rights) of any other party;</li>
              <li>is in breach of any legal duty owed to a third party including, but not limited to, contractual duties and duties of confidence;</li>
              <li>is unlawful or otherwise objectionable;</li>
              <li>is any form of commercial advertising;</li>
              <li>impersonates other people, particularly employees and representatives of Shap or Our affiliates; and</li>
              <li>involves using Our System for unauthorised mass-communication such as "spam" or "junk mail".</li>
            </ul>

            <h2>Member Content</h2>
            <p>
              1. Member Content on Our Website includes (but is not necessarily limited to) Member Profiles, reviews, comments, and media (photo and videos).
            </p>
            <p>
              2. You agree that you will be solely responsible for your Member Content. Specifically, you agree, represent, and warrant that you have the right to submit the Member Content and that all such Member Content will comply with Our Acceptable Usage Policy, detailed below in Clause 12.
            </p>
            <p>
              3. You agree that you will be liable to Us and will, to the fullest extent permissible by law, indemnify Us for any breach of the warranties given by you under sub-Clause 8.6. You will be responsible for any loss or damage suffered by Us as a result of such breach.
            </p>
            <p>
              4. If you wish to remove Content, you may do so. Your Content will be deleted. Please note that caching or references to your Content may not be made immediately unavailable (or may not be made unavailable at all where they are outside of Our reasonable control).
            </p>
            <p>
              5. We may reject, reclassify, or remove any Content submitted to Our Website where, in Our sole opinion, the content of those Content violates Our Acceptable Usage Policy, or if We receive a complaint from a third party and determine that the Content in question should be removed as a result. For more details, please refer to our Acceptable Use Policy.
            </p>

            <h2>Legal Rights and Disclaimers</h2>
            <p>
              1. We make no warranty or representation that the Website will be compatible with all systems, or that it will be secure.
            </p>
            <p>
              2. Whilst We exercise all reasonable skill and care to ensure that the Website is secure and free of errors, viruses, and other malware, you are strongly advised to take responsibility for your own internet security, that of your personal details and your gadgets.
            </p>

            <h2>Links to Other Websites</h2>
            <p>
              This Website may contain links to other sites. Unless expressly stated, these sites are not under the control of Shap or that of Our affiliates. We assume no responsibility for the content of such websites and disclaim liability for any and all forms of loss or damage arising out of the use of them. The inclusion of a link to another site on this Website does not imply any endorsement of the sites themselves or of those in control of them.
            </p>

            <h2>Links to this Website</h2>
            <p>
              Those wishing to place a link to this Website on other sites may do so with Our prior permission. To find out more please contact Us by email at discover@shap.buzz.
            </p>

            <h2>Communications</h2>
            <p>
              1. All notices/communications shall be given to Us either by email to subscriptions@shap.buzz. Such notice will be deemed received the day of sending if the email is received in full on a business day and on the next business day if the email is sent on a weekend or public holiday.
            </p>
            <p>
              2. We may from time to time, if you opt to receive it, send you information about Our products and/or services. If you do not wish to receive such information, please click the unsubscribe link.
            </p>

            <h2>Law and Jurisdiction</h2>
            <p>
              1. These Terms and Conditions, and the relationship between you and Us (whether contractual or otherwise) shall be governed by, and construed in accordance with, Kenyan law.
            </p>
            <p>
              2. Any disputes concerning these Terms and Conditions, the relationship between you and Us, or any matters arising therefrom or associated therewith (whether contractual or otherwise) shall be subject to the jurisdiction of the courts of Kenya.
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  );
}