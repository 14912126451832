import React, {useState, useEffect, useRef, useContext, useCallback} from 'react';
import {Container,Stack} from 'react-bootstrap';
import { Outlet } from "react-router-dom";
import {TransparentNavBar, NavBar, BlackNavBar} from '../components/NavBar';
import {AltFooter} from '../components/Footer';
import {BottomToolbar} from '../components/BottomToolbar';

export const AppLayout = ({showNavBtn, loggedIn}) => {

	return 	<div style={{display: 'flex', flexDirection: 'column', height: '100%', fontFamily:'Merriweather'}}>
				<BlackNavBar loggedIn={loggedIn} showNavBtn={showNavBtn}/>
				<Container className="p-1" style={{flex:'1', width:'100%',height:'100%',position: 'relative',marginTop:80,marginBottom:80,paddingBottom:10}}>
					<Outlet/>
				</Container>
				<BottomToolbar/>
			</div>
}