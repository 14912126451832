import { useRouteError } from "react-router-dom";
import {Container} from 'react-bootstrap';
import {TransparentNavBar, NavBar, BlackNavBar} from '../components/NavBar';
import {AltFooter} from '../components/Footer';

export default function ErrorPage() {
  const error = useRouteError();
  console.error(error);

  return (
    <div id="error-page" style={{fontFamily:'Merriweather'}}>
      <BlackNavBar loggedIn={true}/>
      <Container style={{minHeight:400,width:'100%',position: 'relative',marginTop:80,paddingTop:40}}>
        <h1>Oops!</h1>
        <p>Sorry, an unexpected error has occurred.</p>
        <p>
          <i>{error.statusText || error.message}</i>
        </p>
      </Container>
      <AltFooter/>
    </div>
  );
}