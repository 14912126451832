import React, {useState} from 'react';
import { Container, Card, Col, Row } from 'react-bootstrap';
import {CreatePostInput, PostCard, MiniAvatar, Avatar, CardButton} from '../../components/ModularComponents';
import {postData} from '../../data';
import '../../css/profile.css';

export const Timeline = () => {
	const [publik,setPublik] = useState(true);
    const [username, setUsername] = useState("johndoe");
    const [userAvatar, setUserAvatar] = useState("");
	const disablePrivacy = false;

    return (
        <Container className="p-0" style={{marginTop:10}}>
            <CreatePostInput/>
            {postData?.map((value,index) => <PostCard post={value} />)}
        </Container>
    );
}