import React, {useState, useEffect, useRef, useContext, useCallback} from 'react';
import {Container,Row,Col,Card, Nav} from 'react-bootstrap';
import {useNavigate, useLocation} from 'react-router-dom';
import {CreatePostInput, PostCard, MiniAvatar, Avatar, CardButton} from '../components/ModularComponents';
import {postData} from '../data';

export const Buzz = () => {
	const navigate = useNavigate();
	const location = useLocation();
    const { pathname } = location;

	return  <Container style={{padding:0,margin:0}}>
				<Row>
					<Col xs={12} md={3} className="d-none d-md-block">
						<Card>
							<Card.Header>
								<Card.Text style={{fontWeight:'bold',
													fontSize:16}}>Online users</Card.Text>
							</Card.Header>
							<Card.Body>
								<Card.Text>No users online</Card.Text>
							</Card.Body>
						</Card>
					</Col>
					<Col xs={12} md={6}>
						<Container className="p-0" style={{marginTop:0}}>
				            <Nav className="d-md-none flex-grow-1 d-flex flex-rowflex-wrap  justify-content-around border border-1 rounded-3 align-items-stretch mb-1 pt-0 pb-0" style={{borderRadius: '8px',overflow:'hidden'}}>
								<Col xs={4} md={3} className="nav-col-custom">
									<Nav.Link onClick={() => navigate("/app/buzz")} className="border-end text-center" style={{fontSize: '0.7rem', color: (pathname === '/app/buzz' ? 'white' : '#0AC5BF'), backgroundColor:(pathname === '/app/buzz' ? '#0AC5BF' : 'white') }}>
										<i className="bi bi-clock-history"></i> Newsfeed
									</Nav.Link>
								</Col>
								<Col xs={4} md={3} className="nav-col-custom">
									<Nav.Link onClick={() => navigate("/app/buzz/connectedUsers")} className="border-end text-center" style={{fontSize: '0.7rem', color: (pathname === '/app/buzz/onlineUsers' ? 'white' : '#0AC5BF'), backgroundColor:(pathname === '/app/buzz/onlineUsers' ? '#0AC5BF' : 'white') }}>
										<i className="bi bi-people"></i> Online users
									</Nav.Link>
								</Col>
								<Col xs={4} md={3} className="nav-col-custom">
									<Nav.Link onClick={() => navigate("/app/buzz/members")} className="text-center" style={{fontSize: '0.7rem', color: (pathname === '/app/buzz/members' ? 'white' : '#0AC5BF'), backgroundColor:(pathname === '/app/buzz/memebers' ? '#0AC5BF' : 'white') }}>
										<i className="bi bi-people"></i> Members
									</Nav.Link>
								</Col>
							</Nav>
				            <CreatePostInput/>
				            {postData?.map((value,index) => <PostCard post={value} />)}
				        </Container>
					</Col>
					<Col xs={12} md={3} className="d-none d-md-block">
						<Card>
							<Card.Header>
								<Card.Text style={{fontWeight:'bold',
													fontSize:16}}>Members</Card.Text>
							</Card.Header>
							<Card.Body>
								<Card.Text>No members</Card.Text>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</Container>
}