import React, {useState, useEffect, useRef, useContext, useCallback} from 'react'
import {Container,Stack,Card,Button,Row,Col,Image} from 'react-bootstrap';
import {useNavigate} from 'react-router-dom';
import recreation from '../assets/recreation.jpg';
import sexuality from '../assets/sexuality.jpg';
import lifestyle from '../assets/lifestyle.jpg';
import eroticism from '../assets/eroticism.jpg';

const GroupCard = ({label, path, image}) => {
	const navigate = useNavigate();
	return <Card onClick={() => navigate(path)} style={{backgroundColor:'black',cursor:'pointer'}}>
				<Card.Body>
					<Row>
						<Col xs={12} md={6} className="d-flex justify-content-end align-items-end">
							<Button style={{backgroundColor:'transparent',
											color:'#fed700',
											fontWeight:'bold',
											borderWidth:0,
											borderColor:'#fed700'}}>{label}</Button>
						</Col>
						<Col xs={12} md={6}>
							{image&&<Image src={image} style={{maxHeight:40}} fluid/>}
						</Col>
					</Row>
				</Card.Body>
			</Card>
}

export const Groups = () => {

	return 	<Container>
				<Row>
					<Col xs={12} md={3} className="mb-1">
						<GroupCard label="Recreation" path="/app/groups/recreation" image={recreation} />
					</Col>
					<Col xs={12} md={3} className="mb-1">
						<GroupCard label="Sexuality" path="/app/groups/sexuality" image={sexuality}/>
					</Col>
					<Col xs={12} md={3} className="mb-1">
						<GroupCard label="LifeStyle" path="/app/groups/lifestyle" image={lifestyle}/>
					</Col>
					<Col xs={12} md={3} className="mb-1">
						<GroupCard label="Eroticism" path="/app/groups/eroticism" image={eroticism} />
					</Col>
				</Row>
			</Container>
}