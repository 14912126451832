import React, {useState, useEffect, useRef, useContext, useCallback} from 'react'
import '../css/cover.css';
import { Navbar, Nav, Button, Form, Container, Row, Col, Card, InputGroup, Image } from 'react-bootstrap';
import {useNavigate} from 'react-router-dom';
import logo from '../assets/logo.png';
import image001 from '../assets/image001.jpg';
import brownBg from '../assets/brown-background.jpg';
import image002 from '../assets/websiteImage.png';
import {TransparentNavBar, NavBar, BlackNavBar} from '../components/NavBar';
import {Footer} from '../components/Footer';

export const Discover = () => {
  const navigate = useNavigate();

  return  <div>
    <div style={{backgroundColor:'black'}}>
        {/* Transparent Navbar */}
        <BlackNavBar loggedIn={true}/>
        <div className="cover-image-container" style={{ position: 'relative', height: '100vh', overflow: 'hidden' }}>
          <img
            src="cover-image.jpg"
            alt="Cover"
            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
          />
          <Container fluid className="d-flex align-items-center" 
                      style={{position: 'absolute',
                            top: '0',
                            left: '0',
                            width: '100%',
                            height: '100%',
                            marginLeft:60,marginRight:60}}>
                <Container>
                  <Card>
                    <Card.Body>
                      <InputGroup className="mb-3">
                        <Form.Control
                          placeholder="Search"
                          aria-label="Search"
                          aria-describedby="basic-addon1"
                        />
                        <InputGroup.Text id="basic-addon1">
                          <i class="bi bi-search"></i>
                        </InputGroup.Text>
                      </InputGroup>
                      <InputGroup className="mb-3">
                        <Form.Control
                          placeholder="Age range"
                          aria-label="age-range"
                          aria-describedby="basic-addon1"
                        />
                      </InputGroup>
                      <InputGroup className="mb-3">
                        <Form.Control
                          placeholder="Kinks"
                          aria-label="kinks"
                          aria-describedby="basic-addon1"
                        />
                      </InputGroup>
                      <Button style={{backgroundColor:'#0AC5BF', color:'white', borderColor:'#0AC5BF'}} size="lg">Search</Button>
                    </Card.Body>
                  </Card>
                </Container>
                <Container fluid>
                  <Image src={image002} fluid />
                </Container>
          </Container>
        </div>
    </div>
    <Container>
      <Card style={{ width: '100%', marginTop:20, marginBottom:20, borderColor:'transparent' }}>
        <Card.Body>
          <p>No Search result</p>
        </Card.Body>
      </Card>
    </Container>
    <Footer/>
  </div>
}