import React, {useState, useEffect, useRef, useContext, useCallback} from 'react'
import { Container, Row, Col } from 'react-bootstrap';

export const PrivacyPolicy = () => {

	return  <Container className="mt-4">
            <Row>
              <Col>
                <div className="text-justify">
                  <h2>PRIVACY POLICY</h2>
                  <p>This Policy sets out the obligations of Milu Tilleli Limited, trading as Shap(“Shap”) regarding data protection and the rights of its service users (“Data Subjects”) with respect to your use of this website (“the Website”).</p>
                  <p>We respect the privacy of every person who registers with Shapor visits the Website and we are committed to ensuring a safe online experience.</p>
                  <p><strong>1 – PURPOSE OF THIS NOTICE</strong></p>
                  <p>This privacy notice explains our approach to any personal information that you might supply to us (or that might be collected from you) in connection with your use of the Website and sets out your rights in respect of Shap’s processing of your personal information.</p>
                  <p>This privacy notice will inform you how you can verify which of your personal information is collected by us through the Website and how you can request that we delete, update or transfer it.</p>
                  <p>This privacy notice is intended to assist you in making informed decisions when using the Website. Please take a moment to read and understand it. Please note that it should be read in conjunction with our Terms of Service.</p>
                  <p>Please also note that this privacy notice only applies to the use of personal information collected by us from your use of the Website or during your communications with us. It does not apply to personal information collected during your communications with third parties (including any partners associated with Shap).</p>
                  <p><strong>2 – WHO ARE WE AND WHAT DO WE DO?</strong></p>
                  <p>Shap’s principal activities include operating a private members’ club and associated website. Please be aware that when you create a profile on Shap, your profile will be publicly available to other members.</p>
                  <p>– HOW TO CONTACT US?</p>
                  <p>If you have any questions about this privacy notice or want to exercise your rights, please contact us by sending an email to support@shap.buzz </p>
                  <p><strong>4 – WHAT PERSONAL INFORMATION DO WE COLLECT AND HOW DO WE DO IT?</strong></p>
                  <p>Our primary goal in collecting personal information from you is to give you a customized experience while allowing us to provide services and features that most likely meet your needs. To do so personal information may be collected from you in the following ways:</p>
                  <p>Information you voluntarily provide to us.</p>
                  <p>We collect and maintain personal information that you voluntarily submit to us during your use of the the Website (e.g. when registering with the Website, subscribing to membership, during the course of your interactions and communications with any other members, through any email exchanges with us or as part of any customer support interactions).</p>
                  <p>In particular, to sign upand create an account we require the following personal information from you:</p>
                  <ul>
                    <li>e-mail address;</li>
                    <li>date of birth;</li>
                    <li>gender;</li>
                  </ul>
                  <p>To subscribe to paid membership, our payment processor requires the following and other information from you (we do not collect or store payment information). You should check with your payment processor what information they collect and store:</p>
                  <p>name;</p>
                  <p>email;</p>
                  <p>password;</p>
                  <p>payment method and;</p>
                  <p>billing address.</p>
                  <p>Through your use of the Website, we may also ask you for the following additional personal information:</p>
                  <p>your photo;</p>
                  <p>description of yourself / your body;</p>
                  <p>sexual preferences/interests;</p>
                  <p>town/country; and;</p>
                  <p>current relationshipstatus.</p>
                  <p>You have the flexibility to provide us with as little or as much of this information as possible. However, the more information you provide, the more you will get out of the Website.</p>
                  <p>Information we collect through your use of the Website.</p>
                  <p>We may collect through our use of cookies, pixels, log files and other technologies personal information regarding your mobile device, computer hardware and software used to access the Website. This may include the following:</p>
                  <p>Ipaddress of device(s) used;.</p>
                  <p>browser type;</p>
                  <p>operating system and device type;</p>
                  <p>approximate location</p>
                  <p>access times and dates;</p>
                  <p>onsite navigation/clicks;</p>
                  <p>transaction history and</p>
                  <p>referring website addresses.</p>
                  <p>We may also collect personal information regarding your use of and activity on the Website.</p>
                  <p><strong>5 – HOW DO WE USE YOUR PERSONAL INFORMATION?</strong></p>
                  <p>We collect and use your personal information to operate the Website efficiently and to deliver you the very best service.  In particular, we may use your personal information for the following purposes:</p>
                  <p>Provision of the Website</p>
                  <p>We use your information to identify you and your account and to provide you with the features of the Shapservices related to your membershipand the information submitted and collected. We must use your personal information to provide the Website to you and perform our obligations by the contract that we have with you. It is also in our legitimate interest to use your personal information in such a way to ensure that we provide the very best service we can to you.</p>
                  <p>We may use your information for customer profiling to ensure that we personalize the most suitable services for you.</p>
                  <p>Service administration</p>
                  <p>We may use your personal information to:</p>
                  <p>developand improve the Website;</p>
                  <p>send you administrative e-mails about the Website, Shapand its services; and</p>
                  <p>contact you to answer any queries you may have.</p>
                  <p>Internal record keeping and legal compliance</p>
                  <p>We may use your personal information for internal record-keeping purposes and to check you comply with the Code of Conduct and Terms of Use.  It is in our legitimate interest to ensure that we keepour records up-to-date to monitor who uses the Website, to comply with our legal obligations and to monitor your compliance with the Terms of Use.</p>
                  <p><strong>Marketing by us</strong></p>
                  <p>We may send you information about our services or products that we think may be of interest to you.</p>
                  <p>Sharing information with third-party processors</p>
                  <p>We may share your personal information with third-party processors to provide the Website and associated products and services to you. These are suppliers of ours who for example provide us with site hosting or other technology services. When we share your sensitive personal data, we shall be relying on your consent to do so.</p>
                  <p>Sharing information with professional advisors & potential purchasers.</p>
                  <p>We may share your personal information with professional advisers or regulatory authorities:</p>
                  <p>(I) – to enable them to process your personal information on our behalf in a manner consistent with this privacy notice;</p>
                  <p>(ii) – to comply with our legal obligations;</p>
                  <p>(iii) – to enforce our legal rights; or</p>
                  <p>(iv) – protect rights of third parties.</p>
                  <p>Your personal information may also be shared with prospective buyers if we go through a business transition such as a merger, acquisition by another company, or sale of all or a portion of our assets.</p>
                  <p>It is in our legitimate interest to share your personal information with such third parties to enable them to process your information on our behalf, to comply with and/or enforce our legal rights and obligations, to protect the rights of third parties and to facilitate business transitions. When we share your sensitive personal data, we shall be relying on your consent to do so.</p>
                  <p>Any other purposes for which we wish to use your personal information will be notified to you either by updating our privacy notice or otherwise from time to time including the legal basis of such use.</p>
                  <p><strong>6 – HOW DO WE OBTAIN YOUR CONSENT?</strong></p>
                  <p>Where the use of your personal information by us requires your consent, you can provide such consent:</p>
                  <p>at the time we collect your personal information following the instructions provided, for example at the point of application for membership; or</p>
                  <p><strong>7 – USE OF COOKIES</strong></p>
                  <p>The technologies we use to automatically collect data from you may include:</p>
                  <p>Cookies & Web Tracking. We use “cookies” to keeptrack of some types of information while you are accessing or using our Services. Cookies are very small files placed on your computer, and they allow us to count the number of visitors to our Website and distinguish repeat visitors from new visitors. They also allow us to save user preferences and track user trends. We rely on cookies for the proper operation of our Websites; therefore, if your browser is set to reject all cookies, the website will not function properly. Some cookies are automatically erased after the end of the browser session (these are “session cookies”), whereas other cookies are stored for a predetermined amount of time or permanently in your browser before they erase themselves (these are “temporary" or "permanent" cookies). While a cookie may distinguish your device, it will not name you. </p>
                  <p>What kind of cookies does Shapuse?</p>
                  <p>Strictly necessary cookies</p>
                  <p>We use certain cookies to make sure that our Website and our services are easy, secure and safe to use. Without these cookies, services that you have asked for (such as secure websites and services), would not be possible. This category of cookies  includes:</p>
                  <p>» Cookies that identify or authenticate our users to ensure that our service is secure;</p>
                  <p>» Cookies that temporarily store certain user entries;</p>
                  <p>» Cookies that store certain user preferences (such as your language choices).</p>
                  <p>Analytics cookies</p>
                  <p>We use analytics cookies to record usage data about our users (e.g. which users have visited which of our subpages, etc.) and to evaluate this information statistically.</p>
                  <p>How can you deactivate or erase our cookies?</p>
                  <p>You can deactivate cookies on your device via your browser settings, and you can erase any cookies already stored on your device at any time in your browser. Please note, however, that if you choose to deactivate or remove cookies, doing so may prevent certain features on our Websites from working properly and therefore affect your experience on our Websites. In addition, you may not be able to use all the features of our Service if you deactivate or remove cookies.</p>
                  <p>Web Beacons. “Web beacons” (also known as “clear gifs” and “pixel tags”) are small transparent graphic images that are often used in conjunction with cookies to further personalize our website for our users and to collect a limited set of information about our visitors. We may also use web beacons in email communications to understand the behaviour of our</p>
                  <p>Web Analytics. We collect and use statistical information about your use of our Services to make it more user-friendly, to understand our market share, to conduct other market research and to make sure that we display relevant advertisements for To this end, we work with various authorized service providers; and we use analytics tools in our Services. However, these tools will not use your Ipaddress, or (if they do), they will shorten it immediately after collection (which means that it will be less clearly linked to your device). These tools will also generate user profiles by using analytic cookies or by evaluating log files; however, these user profiles will not be linked to your real-world information and will not name you.</p>
                  <p><strong>8 – THIRD PARTY LINKS AND SERVICES</strong></p>
                  <p>The Website may contain links to third-party websites and services. Please remember that when you use a link to go from  ShapWebsite to another website or you request a service from a third party, this privacy policy no longer applies.</p>
                  <p>Your browsing and interaction on any other website, or your dealings with any other third-party service provider, is subject to that website’s or third-party service provider’s own rules and policies.</p>
                  <p>We do not monitor, control, or endorse the privacy practices of any third parties.</p>
                  <p>We encourage you to become familiar with the privacy practices of every website you visit or third-party service provider that you deal with and to contact them if you have any questions about their respective privacy policies and practices.</p>
                  <p>This privacy notice applies solely to personal information collected by us through the Website and does not apply to these third-party websites and third-party service providers.</p>
                  <p><strong>9 – HOW LONG DO WE KEEpYOUR PERSONAL INFORMATION FOR?</strong></p>
                  <p>We do not keepyour data for any specific period but will not keepit for longer than is necessary for our purposes.</p>
                  <p>In considering how long to keepit, we will take into account its relevance to our business and our legal and regulatory obligations.</p>
                  <p><strong>10 – CONFIDENTIALITY AND SECURITY OF YOUR PERSONAL INFORMATION</strong></p>
                  <p>We are committed to keeping the personal information you provide us secure and we will take reasonable precautions to protect your personal information from loss, misuse or alteration.</p>
                  <p>We have implemented information security policies, rules and technical measures to protect the personal information that we have under our control from:</p>
                  <p>unauthorized access;</p>
                  <p>improper use or disclosure;</p>
                  <p>unauthorized modification; and</p>
                  <p>unlawful destruction or accidental loss.</p>
                  <p>All our employees and data processors (i.e. those who process your personal information on our behalf, for the purposes listed above), who have access to, and are associated with the processing of personal information, are obliged to respect the confidentiality of the personal information of all users of the Website.</p>
                  <p><strong>11 – HOW TO ACCESS YOUR INFORMATION AND YOUR OTHER RIGHTS?</strong></p>
                  <p>You have the following rights regarding the personal information we hold about you:</p>
                  <p>Your right of access.</p>
                  <p>If you ask us, we’ll confirm whether we’re processing your personal information and, if so, provide you with a copy of that personal information (along with certain other details). If you require additional copies, we may need to charge a reasonable fee.</p>
                  <p>Your right to rectification.</p>
                  <p>If the personal information we hold about you is inaccurate or incomplete, you’re entitled to have it rectified. If we’ve shared your personal information with others, we’ll let them know about the rectification where possible. If you ask us, where possible and lawful to do so, we’ll also tell you who we’ve shared your personal information with so that you can contact them directly.</p>
                  <p>Your right to erasure.</p>
                  <p>You can ask us to delete or remove your personal information in some circumstances such as where we no longer need it or you withdraw your consent (where applicable). If we’ve shared your personal information with others, we’ll let them know about the erasure where possible. If you ask us, where possible and lawful to do so, we’ll also tell you who we’ve shared your personal information with so that you can contact them directly.</p>
                  <p>Your right to restrict processing.</p>
                  <p>You can ask us to ‘block’ or suppress the processing of your personal information in certain circumstances such as where you contest the accuracy of that personal information or object to us processing it. It won’t stopus from storing your personal information though. We’ll tell you before we lift any restrictions. If we’ve shared your personal information with others, we’ll let them know about the restriction where possible. If you ask us, where possible and lawful to do so, we’ll also tell you who we’ve shared your personal information with so that you can contact them directly.</p>
                  <p>Your right to data portability.</p>
                  <p>You have the right, in certain circumstances, to obtain personal information you’ve provided us with (in a structured, commonly used and machine-readable format) and to reuse it elsewhere or ask us to transfer this to a third party of your choice.</p>
                  <p>Your right to object.</p>
                  <p>You can ask us to stopprocessing your personal information, and we will do so if we’re:</p>
                  <p>– relying on our own or someone else’s legitimate interests to process your personal information except if we can demonstrate compelling legal grounds for the processing;</p>
                  <p>– processing your personal information for direct marketing; or</p>
                  <p>– processing your personal information for research unless such processing is necessary for the performance of a task carried out in the public interest.</p>
                  <p>Your rights regarding automated decision-making and profiling.</p>
                  <p>You have the right not to be subject to a decision when it’s based on automatic processing, including profiling, and it produces a legal effect or similarly significantly affects you unless such profiling is necessary for entering into, or the performance of, a contract between you and Shap</p>
                  <p>Your right to withdraw consent.</p>
                  <p>If we rely on your consent (or explicit consent) as our legal basis for processing your personal information, you have the right to withdraw that consent at any time.</p>
                  <p><strong>12 – CHANGE TO THIS PRIVACY NOTICE</strong></p>
                  <p>We may make changes to this privacy notice from time to time.</p>
                  <p>To ensure that you are always aware of how we use your personal information we will update this privacy notice from time to time to reflect any changes to our use of your personal information. We may also make changes as required to comply with changes in applicable law or regulatory requirements. We may notify you by e-mail of any significant changes. However, we encourage you to review this privacy notice periodically to be informed of how Shapuses your personal information.</p>
                  <p>Last Updated March 2024</p>
                </div>
              </Col>
            </Row>
          </Container>
}