import React, {useState, useEffect, useRef, useContext, useCallback} from 'react';
import { Navbar, Nav, Button, Form, Container, Row, Col, Card, InputGroup, DropdownButton, Dropdown, Image } from 'react-bootstrap';
import { useAuth } from '../hooks/useAuth';
import logo from '../assets/logo.png';
import { Link, useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import {NavBar} from '../components/NavBar';
import {Footer} from '../components/Footer';
import {africanCountriesWithCodes} from '../data';
import signupImg from '../assets/signupImage.png';
import '../css/gradientBtn.css';
import '../css/linkEl.css';
import {MyCheckBox,MyRadioInputs} from '../components/ModularComponents';

const africanCountries = [
	'Algeria', 'Angola', 'Benin', 'Botswana', 'Burkina Faso', 'Burundi',
	'Cabo Verde', 'Cameroon', 'Central African Republic', 'Chad', 'Comoros',
	'Democratic Republic of the Congo', 'Djibouti', 'Egypt', 'Equatorial Guinea',
	'Eritrea', 'Eswatini', 'Ethiopia', 'Gabon', 'Gambia', 'Ghana', 'Guinea',
	'Guinea-Bissau', 'Ivory Coast', 'Kenya', 'Lesotho', 'Liberia', 'Libya',
	'Madagascar', 'Malawi', 'Mali', 'Mauritania', 'Mauritius', 'Morocco',
	'Mozambique', 'Namibia', 'Niger', 'Nigeria', 'Republic of the Congo',
	'Rwanda', 'Sao Tome and Principe', 'Senegal', 'Seychelles', 'Sierra Leone',
	'Somalia', 'South Africa', 'South Sudan', 'Sudan', 'Tanzania', 'Togo',
	'Tunisia', 'Uganda', 'Zambia', 'Zimbabwe'
  ];

const getRange = (num1, num2, increment) => {
    if (increment <= 0) {
        throw new Error("Increment must be greater than 0");
    }

    const result = [];
    for (let i = num1; i < num2; i += increment) {
        result.push(i);
    }
    return result;
}

const getYear = (date) => {
    return new Date(date).getFullYear();
}

const getMonth = (date) => {
    return new Date(date).getMonth();
}

const getDate = (date) => {
    return new Date(date).getDate();
}


const CustomDatePicker = () => {
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [startDate, setStartDate] = useState('01/01/1999');
  const years = getRange(1924,2000,1);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return (
	<>
		<Form.Control
			placeholder="Date of Birth"
			aria-label="Date of Birth"
			aria-describedby="basic-addon1"
			onFocus={() => setShowDatePicker(true)}
			value={`${getDate(startDate)}/${getMonth(startDate) + 1}/${getYear(startDate)}`}
			/>
		{showDatePicker&&<DatePicker
		inline
		renderCustomHeader={({
			date,
			changeYear,
			changeMonth,
			decreaseMonth,
			increaseMonth,
			prevMonthButtonDisabled,
			nextMonthButtonDisabled
		}) => (
			<>
				<div className="d-flex justify-content-end align-items-end" style={{paddingRight:8,paddingLeft:8}}>
					<Button onClick={() => setShowDatePicker(false)} style={{backgroundColor:'transparent',borderColor:'transparent',padding:0,margin:0}}>
						<i class="bi bi-x-circle-fill" style={{color:'#888',fontSize:30}}></i>
					</Button>
				</div>
				<div 	style={{
							margin: 10,
							display: "flex",
							justifyContent: "center"
						}}
				>
				<Button style={{backgroundColor:'transparent',borderColor:'#888'}} onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
					<i class="bi bi-chevron-left" style={{color:'black'}}></i>
				</Button>
				<select
					value={getYear(date)}
					onChange={({ target: { value } }) => changeYear(value)}
				>
					{years.map((option) => (
					<option key={option} value={option}>
						{option}
					</option>
					))}
				</select>
				<select
					value={months[getMonth(date)]}
					onChange={({ target: { value } }) =>
					changeMonth(months.indexOf(value))
					}
				>
					{months.map((option) => (
					<option key={option} value={option}>
						{option}
					</option>
					))}
				</select>
				<Button style={{backgroundColor:'transparent',borderColor:'#888'}} onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
					<i class="bi bi-chevron-right" style={{color:'black'}}></i>
				</Button>
				</div>
			</>
		)}
		selected={startDate}
		onChange={(date) => setStartDate(date)}
		/>}
	</>
  );
};


export const Signup = () => {
	const [selectedCountry, setSelectedCountry] = useState(null);
	const [city, setCity] = useState({});
	const navigate = useNavigate();
	const { login } = useAuth();
	const [togglePassphaseView, setTogglePhraseView] = useState(false);
	const [cbState, setCbState] = useState(false);
	const [cbState1, setCbState1] = useState(false);
	const [cbState2, setCbState2] = useState(false);
	const [valueArr, onSelect] = useState([false, false, false]);
	const [valueArr1, onSelect1] = useState([false, false, false])

	const handleSignup = () => {
		login({
			id: '1',
			name: 'John Doe',
			email: 'john.doe@email.com',
		});
	};

	return 	<>
			<NavBar loggedin={true} showNavBtn={false} />
			<Container fluid className="d-flex" style={{backgroundColor:'black',marginTop:80,fontFamily:'Merriweather'}}>
				<Row>
					<Col xs={12} md={6}>
						<Container>
							<Row xs="auto" md="auto" lg="auto" xxl="auto" className="d-flex justify-content-center">
								<Card style={{backgroundColor:'black'}}>
									<Card.Body>
										<InputGroup className="mb-3">
									    	<div className="d-flex align-items-center">
												<MyCheckBox cbState={cbState} setCbState={setCbState} />
												<span style={{marginLeft:5,color:'white'}}>affirm that I will abide by the<Link to="/text/guidelines" style={{marginLeft:3}}> interaction guidelines</Link> I aim to contribute positively to our collective experience.*</span>
											</div>									
					        			</InputGroup>
										<InputGroup className="mb-3">
											<InputGroup.Text id="basic-addon1">
												<i class="bi bi-calendar3"></i>
											</InputGroup.Text>
									        <CustomDatePicker/>
									    </InputGroup>
									    <MyRadioInputs valueArr={valueArr} onSelect={onSelect}/>
									    <MyRadioInputs label="Current gender" valueArr={valueArr1} onSelect={onSelect1}/>
										<InputGroup className="mb-3">
											<Form.Control placeholder="Country" onChange={(e) => setSelectedCountry(e.target.value)} as="select" aria-label="Country" aria-describedby="basic-addon1">
												<option style={{ color: 'gray' }} value="" disabled selected>Select a country</option>
												{africanCountries.map((country, index) => 
													<option key={index} value={country}>{country}</option>
												)}
											</Form.Control>
											<InputGroup.Text id="basic-addon1">
												<i class="bi bi-caret-down-fill" style={{ color: 'gray' }}></i>
											</InputGroup.Text>
										</InputGroup>
										<InputGroup className="mb-3">
											<div style={{ width: '100%' }}>
												<GooglePlacesAutocomplete
													containerProps={{
														style: { width:"100%" },
													}}
													inputProps={{
														style: { width: '100%' },
													}}
													placeholder='City'
													loadScript={false}
													apiKey="AIzaSyAKQ5Z_Z3Am958KGMOo4dx6pv9PUvht7I4"
													selectProps={{ onChange: (value) => setCity(value), placeholder: 'City' }}
													autocompletionRequest={{
														types: ['(cities)'],
														componentRestrictions:{country:africanCountriesWithCodes[selectedCountry]}}}
												/>
											</div>
										</InputGroup>
										<InputGroup className="mb-3">
									    	<div className="d-flex align-items-center">
												<MyCheckBox cbState={cbState1} setCbState={setCbState1} />
												<span style={{alignItems:'center',wordWrap:'break-word',marginLeft:5,color:'white'}} id="basic-addon1">
													I certify that(1) I'm at least 25 years old and (2) I have read and consent to the<Link to="/text/terms" style={{marginLeft:3}}> terms of service</Link>
												</span>
											</div>									
					        			</InputGroup>
									    <InputGroup className="mb-3">
											<InputGroup.Text id="basic-addon1">
												<i class="bi bi-person-fill"></i>
											</InputGroup.Text>
									        <Form.Control
									          placeholder="Username"
									          aria-label="Username"
									          aria-describedby="basic-addon1"
									        />
									    </InputGroup>
									    <InputGroup className="mb-3">
											<InputGroup.Text id="basic-addon1">
												<i class="bi bi-envelope-at-fill"></i>
											</InputGroup.Text>
									        <Form.Control
									          placeholder="Email"
									          aria-label="Email"
									          aria-describedby="basic-addon1"
									        />
									    </InputGroup>
									    <InputGroup>
									    	<p style={{color:'white',fontSize:12}}>Passphrase example: "1ts 3@s!er th@n a passw0rd"*</p>
									    </InputGroup>
									    <InputGroup className="mb-3">
											<InputGroup.Text id="basic-addon1">
												<i class="bi bi-key-fill"></i>
											</InputGroup.Text>
									        <Form.Control
									        	type="password"
									          placeholder="Passphrase"
									          aria-label="Password"
									          aria-describedby="basic-addon1"
									        />
									        <InputGroup.Text id="basic-addon1" onClick={()=>setTogglePhraseView(!togglePassphaseView)}>
												{!togglePassphaseView?<i class="bi bi-eye"></i>:<i class="bi bi-eye-slash"></i>}
											</InputGroup.Text>
									    </InputGroup>
									    <InputGroup className="mb-3">
											<InputGroup.Text id="basic-addon1">
												<i class="bi bi-key-fill"></i>
											</InputGroup.Text>
									        <Form.Control
									        	type="password"
									          placeholder="Confirm passphrase"
									          aria-label="Confirm Password"
									          aria-describedby="basic-addon1"
									        />
									        <InputGroup.Text id="basic-addon1" onClick={()=>setTogglePhraseView(!togglePassphaseView)}>
												{!togglePassphaseView?<i class="bi bi-eye"></i>:<i class="bi bi-eye-slash"></i>}
											</InputGroup.Text>
									    </InputGroup>
									    <InputGroup className="mb-3">
									        <Form.Control
									          placeholder="How did you hear about us?"
									          aria-label="How did you hear about us?"
									          aria-describedby="basic-addon1"
									        />
									        <DropdownButton variant="outline-secondary"
													        title=""
													        id="input-group-dropdown-1">
												<Dropdown.Item href="#">Search Engine</Dropdown.Item>
												<Dropdown.Item href="#">Word of mouth</Dropdown.Item>
												<Dropdown.Item href="#">Social media</Dropdown.Item>
												<Dropdown.Item href="#">Targeted Ads</Dropdown.Item>
									        </DropdownButton>
									    </InputGroup>
									    <InputGroup className="mb-3">
									    	<div className="d-flex align-items-center">
												<MyCheckBox cbState={cbState2} setCbState={setCbState2} />
												<span style={{alignItems:'center',wordWrap:'break-word',marginLeft:5,color:'white'}} id="basic-addon1">
													I affirm that I have read and consent to the<Link to="/text/privacyPolicy" style={{marginLeft:3}}> privacy policy</Link>
												</span>
											</div>									
					        			</InputGroup>
									    <InputGroup className="d-grid gap-2" style={{marginTop:10, marginBottom:20}}>
 											<Button className="gradient-border-button" onClick={()=>navigate("/signup")} style={{borderWidth:0, backgroundColor:'transparent', color:'#fed700',padding:'15px 40px 15px 40px'}}>
									          Signup
									        </Button>										
					        			</InputGroup>
									</Card.Body>
								</Card>
							</Row>
						</Container>
					</Col>
					<Col xs={12} md={6}>
						<Container>
							<div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
								<Image src={signupImg} fluid/>
							</div>
							<p style={{color:'white'}}>Join Africa’s adult pleasure playground and explore a diverse array of both sexual and non-sexual conversations and activities. Build new skills, delve into new kinks, try on new erotic personas, or co-create moments of play, pleasure, and excitement with like-minded adults across Africa. Register to discover what brings you pleasure and joy, and find new ways to play!</p>
							<p style={{color:'white'}}>How you choose to relate, play, and explore does not define you. Your essence is more complex and cannot be solely determined by these aspects of yourself. Embrace your interests and desires—confidently express, explore, and experience.</p>
							<div style={{marginTop:20,marginBottom:30}}>
								<Row className="d-flex flex-rowflex-wrap" style={{color:'#feb700'}}>
									<Col xs={6}>
										<b>Membership</b>
									</Col>
									<Col xs={6}>
										<b>Per Month</b>
									</Col>
									<Col xs={6}>
										Sapphire
									</Col>
									<Col xs={6}>
										Monthly $25
									</Col>
									<Col xs={6}>
										Emerald(20% off)
									</Col>
									<Col xs={6}>
										*3 months $20
									</Col>
									<Col xs={6}>
										Ruby(40% off)
									</Col>
									<Col xs={6}>
										*6 months $15
									</Col>
									<Col xs={6}>
										Diamond(60% off)
									</Col>
									<Col xs={6}>
										*12 months $10
									</Col>
								</Row>
							</div>
						</Container>
					</Col>
				</Row>
			</Container>
			<Footer/>
			</>
}