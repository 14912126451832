import React, {useState} from 'react';
import { Container, Card, Col, Row, DropdownButton, InputGroup, Dropdown, Button, Form } from 'react-bootstrap';
import {PostCard, MiniAvatar, Avatar, CardButton} from '../../components/ModularComponents';
import '../../css/profile.css';

const InfoItem = ({label, value, setValue, showStatus, disablePrivacy=false}) => {
	const [publik, setPublik] = useState(true);
	const [edit, setEdit] = useState(false);

	return 	<div className="d-flex align-items-center justify-content-center" style={{borderWidth:1,borderColor:'red'}}>
				{!edit?<>
					{(label !== 'dob' && label !== 'gab')&&<Card.Text style={{fontSize:13,fontWeight:'bold',minWidth:80,marginBottom:0,marginTop:0}}>{label}</Card.Text>}
					{(label === 'dob')&&<Card.Text style={{fontSize:13,fontWeight:'bold',minWidth:80,marginBottom:0,marginTop:0}}>Date of Birth</Card.Text>}
					{(label === 'gab')&&<Card.Text style={{fontSize:13,fontWeight:'bold',minWidth:80,marginBottom:0,marginTop:0}}>Gender at Birth</Card.Text>}
					<Card.Text style={{fontSize:13,width:'100%',marginLeft:10,marginBottom:0,marginTop:0}}>{value}</Card.Text>
					<DropdownButton className="privacyBtn" title={publik?<i class="bi bi-globe-europe-africa" 
																			style={{color:'#0AC5BF'}}></i>
																		:<i class="bi bi-lock"
																			style={{color:'#0AC5BF'}}></i>}
									id="input-group-dropdown-1"
									disabled={disablePrivacy}
									style={{color:'#0AC5BF',borderWidth:0,borderColor:'transparent',backgroundColor:'transparent'}}>
						<Dropdown.Item onClick={() => setPublik(true)}><i class="bi bi-globe-europe-africa"></i> All members</Dropdown.Item>
						<Dropdown.Item onClick={() => setPublik(true)}><i class="bi bi-people-fill"></i> Connections</Dropdown.Item>
						<Dropdown.Item onClick={() => setPublik(false)}><i class="bi bi-lock"></i> Only me</Dropdown.Item>
					</DropdownButton>
					<Button onClick={() => setEdit(true)} style={{backgroundColor:'transparent',borderWidth:0}}>
						<i class="bi bi-pencil" style={{color:'#0AC5BF'}}></i>
					</Button>
				</>
				:<InputGroup style={{margin:'5px 0 5px 0'}}>
					<InputGroup.Text>
						{label === 'username'&&<i class="bi bi-at" style={{color:'#444'}}></i>}
						{label === 'dob'&&<i class="bi bi-cake2" style={{color:'#444'}}></i>}
						{label === 'age'&&<i class="bi bi-calendar3" style={{color:'#444'}}></i>}
						{label === 'gab'&&<i class="bi bi-gender-ambiguous" style={{color:'#444'}}></i>}
						{label === 'city'&&<i class="bi bi-buildings" style={{color:'#444'}}></i>}
						{label === 'country'&&<i class="bi bi-geo-alt" style={{color:'#444'}}></i>}
						{label === 'kink'&&<i class="bi bi-arrow-through-heart" style={{color:'#444'}}></i>}
					</InputGroup.Text>
					<Form.Control
						          placeholder={label === 'dob'?'Date of Birth':label}
						          aria-label={label}
						          aria-describedby="basic-addon1"
								  value={value}
								  onChange={setValue}
						        />
					<Button style={{backgroundColor:'transparent',borderColor:'#eee',color:'#444'}} onClick={() => setEdit(false)}>
						<i class="bi bi-floppy" style={{color:'#444',marginRIght:5}}></i> Save
					</Button>
				</InputGroup>}
			</div>
}

export const Info = () => {
	return <Container className="p-0">
				<Card style={{marginBottom:10}}>
					<Card.Header style={{backgroundColor:'transparent',marginLeft:20,marginRight:20,paddingLeft:0,paddingRight:0}}>
						<Card.Text style={{fontWeight:'bold',fontSize:18, color:'#0AC5BF'}}>Basic Info</Card.Text>
					</Card.Header>
					<Card.Body style={{paddingLeft:20,paddingRight:20}}>
						<InfoItem label="username" value="eli" disablePrivacy={true} showStatus="public"/>
						<InfoItem label="dob" value="April 9th, 1992"/>
						<InfoItem label="age" value="32"/>
						<InfoItem label="gab" value="Male"/>
						<InfoItem label="city" value="Nairobi"/>
						<InfoItem label="country" value="Kenya"/>
						<InfoItem label="kink" value="BDSM"/>
					</Card.Body>
				</Card>
			</Container>

}