export const africanCountriesWithCodes = {
    'Algeria': 'DZ',
    'Angola': 'AO',
    'Benin': 'BJ',
    'Botswana': 'BW',
    'Burkina Faso': 'BF',
    'Burundi': 'BI',
    'Cabo Verde': 'CV',
    'Cameroon': 'CM',
    'Central African Republic': 'CF',
    'Chad': 'TD',
    'Comoros': 'KM',
    'Democratic Republic of the Congo': 'CD',
    'Djibouti': 'DJ',
    'Egypt': 'EG',
    'Equatorial Guinea': 'GQ',
    'Eritrea': 'ER',
    'Eswatini': 'SZ',
    'Ethiopia': 'ET',
    'Gabon': 'GA',
    'Gambia': 'GM',
    'Ghana': 'GH',
    'Guinea': 'GN',
    'Guinea-Bissau': 'GW',
    'Ivory Coast': 'CI',
    'Kenya': 'KE',
    'Lesotho': 'LS',
    'Liberia': 'LR',
    'Libya': 'LY',
    'Madagascar': 'MG',
    'Malawi': 'MW',
    'Mali': 'ML',
    'Mauritania': 'MR',
    'Mauritius': 'MU',
    'Morocco': 'MA',
    'Mozambique': 'MZ',
    'Namibia': 'NA',
    'Niger': 'NE',
    'Nigeria': 'NG',
    'Republic of the Congo': 'CG',
    'Rwanda': 'RW',
    'Sao Tome and Principe': 'ST',
    'Senegal': 'SN',
    'Seychelles': 'SC',
    'Sierra Leone': 'SL',
    'Somalia': 'SO',
    'South Africa': 'ZA',
    'South Sudan': 'SS',
    'Sudan': 'SD',
    'Tanzania': 'TZ',
    'Togo': 'TG',
    'Tunisia': 'TN',
    'Uganda': 'UG',
    'Zambia': 'ZM',
    'Zimbabwe': 'ZW'
};

export const postData = [
    {
        user: {
            avatar: "https://randomuser.me/api/portraits/men/1.jpg",
            username: "johndoe"
        },
        createdAt: "2 hours ago",
        content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla ac nunc eget nisl ullamcorper lacinia. Sed auctor",
        likes: 10,
        comments: 5,
        shares: 2,
        image: "https://picsum.photos/500/300?random=1"
    },
    {
        user: {
            avatar: "https://randomuser.me/api/portraits/women/2.jpg",
            username: "janesmith"
        },
        createdAt: "5 hours ago",
        content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla ac nunc eget nisl ullamcorper lacinia. Sed auctor",
        likes: 15,
        comments: 8,
        shares: 3,
        image: "https://picsum.photos/500/300?random=2"
    },
    {
        user: {
            avatar: "https://randomuser.me/api/portraits/men/3.jpg",
            username: "mikejohnson"
        },
        createdAt: "1 day ago",
        content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla ac nunc eget nisl ullamcorper lacinia. Sed auctor",
        likes: 20,
        comments: 12,
        shares: 4,
        image: "https://picsum.photos/500/300?random=3"
    },
    {
        user: {
            avatar: "https://randomuser.me/api/portraits/women/4.jpg",
            username: "sarahwilliams"
        },
        createdAt: "1 day ago",
        content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla ac nunc eget nisl ullamcorper lacinia. Sed auctor",
        likes: 25,
        comments: 15,
        shares: 5,
        image: "https://picsum.photos/500/300?random=4"
    }
];
