import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import { PayPalButtons } from '@paypal/react-paypal-js';

const PaypalButton = ({ amount }) => {
	// useEffect(() => {
	// 	if(window?.paypal){
	// 		alert('window dot paypal loaded')
	// 	}
	// 	if(window?.paypal?.buttons){
	// 		alert('window dot paypal dot button loaded');
	// 	}
	// },[window?.paypal, window?.paypal?.buttons]);

	return (
		<><PayPalButtons
					style={{ layout: 'vertical' }}
					createOrder={(data, actions) => {
						return actions.order.create({
							purchase_units: [{
								amount: {
									value: amount,
								},
							}],
						});
					}}
					onApprove={(data, actions) => {
						return actions.order.capture().then((details) => {
							alert('Transaction completed by ' + details.payer.name.given_name);
						});
					}}
			/></>
	);
};


export const Checkout = () => {
  const [amount, setAmount] = useState('0.00');
  const [showBtn, setShowBtn] = useState(false);
  //const [{ isPending }] = usePayPalScriptReducer();

  const handleInputChange = (e) => {
    setAmount(e.target.value);
  };

  const handleCheckout = () => {
  	setShowBtn(true)
    // You can handle other checkout-related logic here
  };

  useEffect(() => {
  	if(showBtn){
  		alert('showBtn:'+showBtn)
  	}
  },[showBtn])

  return (
    <Container fluid>
      <Row>
        <Col md={{ span: 6, offset: 3 }}>
          <h2 className="mb-4">Checkout</h2>
          <Form>
            <Form.Group controlId="formAmount">
              <Form.Label>Amount</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter amount"
                value={amount}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Button variant="primary" className="mt-3" onClick={handleCheckout}>
              Checkout
            </Button>
          </Form>
          <div className="mt-4">
            {showBtn&&<PaypalButton amount={amount} />}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

