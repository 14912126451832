import React, {useState, useEffect, useRef, useContext, useCallback} from 'react'
import '../css/cover.css';
import { Navbar, Nav, Button, Form, Container, Row, Col, Card, InputGroup, Image } from 'react-bootstrap';
import {useNavigate} from 'react-router-dom';
import logo from '../assets/logo.png';
import image001 from '../assets/image001.jpg';
import brownBg from '../assets/brown-background.jpg';
import image002 from '../assets/websiteImage.png';
import image003 from '../assets/image003.png';
import bImage from '../assets/bImage.png';
import welcomeImage from '../assets/welcomeImage.png';
import {TransparentNavBar, NavBar, BlackNavBar} from '../components/NavBar';
import {Footer} from '../components/Footer';

export const Welcome = () => {
  const navigate = useNavigate();

  return  <div style={{fontFamily:'Merriweather'}}>
    <div style={{backgroundColor:'black'}}>
        {/* Transparent Navbar */}
        <BlackNavBar loggedIn={false}/>
        <div className="cover-image-container" style={{ position: 'relative', height: '100vh', overflow: 'hidden' }}>
          <img
            src="cover-image.jpg"
            alt="Cover"
            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
          />
          <Container fluid className="d-flex align-items-center justify-content-center" 
                      style={{position: 'absolute',
                            top: '0',
                            left: '0',
                            width: '100%',
                            height: '100%',}}>
                <Row>
                  <Col>
                    <Container className="d-flex align-items-center justify-content-center">
                      <Card style={{borderWidth:0,backgroundColor:'transparent',color:'white'}}>
                        <Image src={welcomeImage} style={{width:245,alignSelf:'center'}}/>
                        <p style={{color:'#fed700',
                                    textAlign:'center',
                                    fontSize:32,
                                    fontWeight:'bold',
                                    fontStyle:'italic'}}>A Social Club for the Adventurous</p>
                        <p style={{textAlign:'center',
                                  color:'#0AC5BF',
                                  fontStyle:'italic',
                                  fontWeight:'bold',
                                  fontSize:18}}>Pleasure, Sexuality and Desire without Judgment or Shame</p>
                      </Card>
                    </Container>
                  </Col>
                </Row>
          </Container>
        </div>
    </div>
    <Container>
      <Row>
        <Col xs={12} md={6}>
          <Card style={{ width: '100%', marginTop:20, marginBottom:20, borderColor:'transparent' }}>
            <Card.Body>
              <p style={{ color: '#0AC5BF', fontStyle: 'italic', fontSize:18, fontWeight:'bold' }}>
                Join us to explore what brings you pleasure and joy, and find new ways to play!
              </p>
              <p style={{fontStyle:'italic'}}>
                Welcome to our vibrant and inclusive social club, where we celebrate the diverse and ever-evolving nature of human desires, sexualities, and interests. Whether driven by curiosity, a thirst for new experiences, a spirit of exploration, or simply the desire to play, our social club warmly welcomes you. Here, you can pursue various desires, find like-minded companions for recreational activities, and engage in sexual exploration. We are dedicated to providing a safe and empowering space for open-minded adults in Africa, prioritizing play, pleasure, and the celebration of diversity and exploration. Join us to embrace novelty, variety, and adventure.
              </p>
            </Card.Body>
          </Card>
        </Col>
        <Col className="p-4" xs={12} md={6}>
          <Image src={image002} fluid/>
        </Col>
      </Row>
    </Container>
    <div style={{backgroundColor:'black'}}>
      <Container>
        <h1 className="pt-4" style={{fontSize:'2em',color:'#FED700', fontStyle:'italic'}}>Play and Pleasure</h1>
        <Row>
          <Col xs={12} md={4} style={{ textAlign: 'justify', color:'white', fontStyle:'italic' }}>
            <h1 style={{fontSize:'10em',color:'#0AC5BF', fontStyle:'normal'}}>01</h1>
            <h4><strong>Recreational Companionship</strong></h4>
            <p>
              We understand the desire to explore new activities or indulge in pleasurable pursuits but not having like-minded people to share them with. While individuals can find joy and fulfillment in pursuing their interests alone, the experience is often elevated when shared with others. That's why we've established a social club for open-minded individuals to engage in their interests alongside like-minded peers. This is one of the best ways to meet new people and start friendships that may last a lifetime. Join our social club, where we prioritize play and pleasure as they serve as fuel, provide a positive uplift, and keep us joyful. Feeling good and connected not only enriches our lives but also contributes to our happiness, health, and sense of connection with ourselves and others. Whether it's savoring a small indulgence or passionately pursuing a hobby, making time for play and pleasure can significantly impact our overall well-being.
            </p>
          </Col>
          <Col xs={12} md={4} style={{ textAlign: 'justify', color:'white', fontStyle:'italic' }}>
            <h1 style={{fontSize:'10em',color:'#0AC5BF',fontStyle:'normal'}}>02</h1>
            <h4><strong>Sexploration</strong></h4>
            <p>
              "When sexual activity is pleasurable, freely chosen, and intrinsically motivated, it aligns with most definitions of leisure activity," as stated by Liza Berdychevsky. Engaging in satisfying sexual activity has been associated with greater overall enjoyment of life, improved quality of life, and increased well-being. Embracing the concept of sex as leisure seamlessly aligns with a sex-positive culture that advocates for the promotion of pleasure, sexual diversity, autonomy, freedom, empowerment, and fulfilling sex lives. We warmly invite you to join our social club, thoughtfully designed to cater to a wide range of interests, sexualities, and desires. Here, you can connect more deeply with your sexuality and explore diverse activities that bring you pleasure. Just like engaging in a favorite hobby, we encourage you to embrace your desires with curiosity and enthusiasm as you navigate your unique path to sexual happiness.
            </p>
          </Col>
          <Col xs={12} md={4} style={{ textAlign: 'justify', color:'white', fontStyle:'italic' }}>
            <h1 style={{fontSize:'10em',color:'#0AC5BF',fontStyle:'normal'}}>03</h1>
            <h4><strong>Mutual Enjoyment</strong></h4>
            <p>
              At the heart of everything our members do is mutual enjoyment. They are dedicated to curating mutually satisfying adventures and experiences in a judgment-free, no-pressure, and accepting environment. They achieve this by ensuring that all parties are genuinely excited and eager to engage in the activity, confidently expressing their needs, uncertainties, boundaries, preferences, curiosities, and desires. They actively listen and respect the boundaries set by others. Through this approach, they engage in experiences that cater to their pleasures and preferences while ensuring that the desires and comfort of their partners are respected. Ongoing and transparent dialogue allows them to continually refine and adapt their experiences, providing unforgettable moments of shared pleasure, joy, and fulfillment. Join us for online and real-life interactions as you explore new places, try new things, and create lasting memories.
            </p>
          </Col>
        </Row>
        <div className="d-flex align-items-center justify-content-center pb-4">
          <Button onClick={() => navigate('/signup')} 
                  className="valuesBtn"
                  style={{width:'100%',maxWidth:400,backgroundColor:'#000', 
                          color:'#fed700', borderColor:'#fed700', borderRadius:20, 
                          borderWidth:3, alignSelf:'center'}}>Say Yes to Pleasure, Join Now</Button>
        </div>
      </Container>
    </div>
    <Container className="pt-4">
      <Row>
        <Col style={{ textAlign: 'justify',fontStyle:'italic' }}>
          <h4 style={{borderBottom:'1px dashed #444', color:'#0AC5BF', display:'inline-block'}}><strong>Personalized Profiles</strong></h4>
          <p>Our private membership social club warmly welcomes open-minded and non-judgmental adults aged 25 and above in Africa, regardless of gender, sexuality, desires, lifestyle, relationship status, or structure. We seek intentional adults who value change, growth, consent, safety, communication, respect, discretion, and who are committed to curating mutually satisfying adventures and experiences.</p>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={3} style={{ textAlign: 'justify',fontStyle:'italic' }}>
          <h1 style={{fontSize:'5em',color:'#0AC5BF',fontStyle:'normal'}}>01</h1>
          <h4><strong>Personalized Profiles</strong></h4>
          <p>
            Craft your personal profile without revealing identifying details. Express your preferences, desires, and interests to foster meaningful connections with like-minded members. Customize the visibility of your profile. A detailed profile empowers both yourself and others to make informed decisions about potential connections, ensuring compatibility and shared interests. Let your profile authentically reflect who you are, enabling seamless connections with members who align with your desires and preferences. Join Shap, where personal profiles act as gateways to discovering new connections and adventures.
          </p>
        </Col>
        <Col xs={12} md={3} style={{ textAlign: 'justify',fontStyle:'italic' }}>
          <h1 style={{fontSize:'5em',color:'#0AC5BF',fontStyle:'normal'}}>02</h1>
          <h4><strong>Interest Groups</strong></h4>
          <p>
            Join interest-based groups to connect with like-minded members who share your diverse interests. Engage in discussions, share experiences, and gain valuable insights in a supportive, judgment-free environment. Our groups offer both Safe for Work (SFW) and Not Safe for Work (NSFW) options, catering to all comfort levels and preferences. Furthermore, whether you're exploring new passions or delving deeper into familiar topics, our social club provides a space for open dialogue and meaningful connections. Join us and embark on a journey of exploration, discovery, and connection in an inclusive environment where every member's voice is valued and respected.
          </p>
        </Col>
        <Col xs={12} md={3} style={{ textAlign: 'justify',fontStyle:'italic' }}>
          <h1 style={{fontSize:'5em',color:'#0AC5BF',fontStyle:'normal'}}>03</h1>
          <h4><strong>Discussion Forums</strong></h4>
          <p>
            Step into our uncensored environment, where you can freely express yourself and explore topics that matter to you in a safe, non-judgmental space. Engage in open conversations, share your thoughts, ask questions, and tell your stories. Additionally, enjoy the option for one-on-one conversations with members you're connected with, send and receive unlimited messages, and experience the immediacy and intimacy of real-time communication through voice messages, audio calls, and video calls, allowing for deeper connections and more personal discussions. Join us and take the next step toward meaningful interactions and fulfilling connections.
          </p>
        </Col>
        <Col xs={12} md={3} style={{ textAlign: 'justify',fontStyle:'italic' }}>
          <h1 style={{fontSize:'5em',color:'#0AC5BF',fontStyle:'normal'}}>04</h1>
          <h4><strong>Activities</strong></h4>
          <p>
            Join our vibrant social club, where activities bring members together for real-life adventures. Participate in our regular in-person meetups to become verified. Once verified, members with subscriptions of over a year have the opportunity to organize their own unique experiences. From casual gatherings to adventurous outings, our club offers a variety of experiences to suit every taste. Let's journey together from light to wild experiences, forging connections and creating memories. Join us and become part of a social club where real-life interactions lead to new, exciting, and unforgettable adventures.
          </p>
        </Col>
      </Row>
      <div className="d-flex align-items-center justify-content-center pb-4">
        <Button onClick={() => navigate('/signup')} 
                className="valuesBtn"
                style={{width:'100%',maxWidth:400,backgroundColor:'#000', 
                        color:'#fed700', borderColor:'#fed700', borderRadius:20, 
                        borderWidth:3, alignSelf:'center'}}>Find your people, Join Now</Button>
      </div>
    </Container>
    <div className="pt-4" style={{backgroundColor:'black'}}>
      <Container>
        <Row>
          <Col xs={12} style={{ textAlign: 'justify', color:'white', fontStyle:'italic' }}>
            <h2 style={{ color: '#fed700', fontStyle: 'italic' }}>
              Embrace Your Desires
            </h2>
            <p>
              Play and Pleasure can sometimes create internal conflicts, especially when it clashes with other aspects of our private and public identities. Often, societal expectations can make us feel judged when our desires diverge. These pressures, whether subtle or overt, dictate what our relationships should look like and what we should desire, often disconnecting us from our true passions. However, reconnecting with these desires can be incredibly rewarding.
            </p>
            <p>
              As noted by Gigi Engle, every fantasy falls into one of three categories: those we keep to ourselves, those we share with partners to spice things up, and those we aspire to explore. At our Social Club, you can freely explore all your fantasies in a safe, non-judgmental environment. We provide a space where you can express yourself openly and engage with like-minded adults in Africa. Trying new experiences brings fresh perspectives and revitalizes our lives. This openness liberates us from conventional thinking, allowing us to embrace the diverse richness of human experience. Join us with an open mind and a commitment to conscious pleasure-seeking. Trust your intuition and grant yourself permission to explore and play.
            </p>
          </Col>
        </Row>
        <div className="d-flex align-items-center justify-content-center pb-4">
          <Button onClick={() => navigate('/signup')} 
                  className="valuesBtn"
                  style={{width:'100%',maxWidth:400,backgroundColor:'#000', 
                          color:'#fed700', borderColor:'#fed700', borderRadius:20, 
                          borderWidth:3, alignSelf:'center'}}>Start exploring, Join Now</Button>
        </div>
      </Container>
    </div>
    <Container className="pt-4">
      <Row>
        <Col xs={12} md={6} style={{ textAlign: 'justify', fontStyle:'italic' }}>
          <h1 style={{ color: '#0AC5BF', fontStyle: 'normal', fontWeight:'bold' }}>
            Our values
          </h1>
          <div className="mb-4">
            <p style={{fontSize:24, lineHeight:'1em', fontStyle:'italic', fontWeight:'bold', marginBottom:0}}>Behind every username is a human being. Let's be closer AF</p>
          </div>
          <p style={{fontStyle:'italic'}}>
            C-Consent, Communication & Consideration
            <br/>
            L-Look out for each other
            <br/>
            O-Open-Minded
            <br/>
            S-Safety
            <br/>
            E-Empathy
            <br/>
            R-Respect
            <br/>
            A-Authentic
            <br/>
            F-Feedback
          </p>
        </Col>
        <Col xs={12} md={6} style={{ textAlign: 'justify', color:'white', fontStyle:'italic' }}>
          <Card style={{border:'0px solid white'}}>
            <Card.Body>
              <Image src={image003} fluid/>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <div className="d-flex align-items-center justify-content-center pb-4">
        <Button onClick={() => navigate('/our-story')} 
                className="valuesBtn"
                style={{width:'100%',maxWidth:400,backgroundColor:'#000', 
                        color:'#fed700', borderColor:'#fed700', borderRadius:20, 
                        borderWidth:3, alignSelf:'center'}}>Our Values in Detail</Button>
      </div>
    </Container>
    <div className="pt-4" style={{backgroundColor:'black'}}>
      <Container>
        <Row>
            <Card className="d-flex justify-content-center align-items-center" 
                  style={{border:'0px solid white',backgroundColor:'transparent'}}>
              <Card.Body>
                <Image src={bImage} fluid/>
              </Card.Body>
            </Card>
        </Row>
        <Row>
          <Col xs={12} md={6} style={{ textAlign: 'justify', color:'white', fontStyle:'italic' }}>
            <Card className="d-flex justify-content-center align-items-center" 
                  style={{border:'0px solid white',backgroundColor:'transparent'}}>
              <Card.Body>
                <h4 style={{color:'white'}}>Become a member today!</h4>
                <Button onClick={() => navigate('/signup')} 
                        className="valuesBtn"
                        style={{width:'100%',maxWidth:500,backgroundColor:'#000', 
                                color:'white', borderColor:'white', borderRadius:20, 
                                borderWidth:3, alignSelf:'center'}}>Join Now</Button>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} md={6} style={{ textAlign: 'justify', color:'white', fontStyle:'italic' }}>
            <Card className="d-flex justify-content-center align-items-center" 
                  style={{border:'0px solid white',backgroundColor:'transparent'}}>
              <Card.Body>
                <h4 style={{color:'white'}}>Work/Invest with us!</h4>
                <Button onClick={() => navigate('/signup')} 
                        className="valuesBtn"
                        style={{width:'100%',maxWidth:500,backgroundColor:'#000', 
                                color:'white', borderColor:'white', borderRadius:20, 
                                borderWidth:3, alignSelf:'center'}}>Get Started</Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
    <Footer/>
  </div>
}