import React from 'react';
import {OurStory} from "./layouts/OurStory";
import {Signup} from "./layouts/Signup";
import {Login} from "./layouts/Login";
import {Checkout} from "./layouts/Checkout";
import {Subscription} from "./layouts/Subscription";
import {Profile} from "./layouts/Profile";
import {Timeline} from './layouts/profilePages/Timeline';
import {Info} from './layouts/profilePages/Info';
import {Connections} from './layouts/profilePages/Connections';
import {Circles} from './layouts/profilePages/Circles';
import {Reviews} from './layouts/profilePages/Reviews';
import {Settings} from "./layouts/Settings";
import {Forums} from "./layouts/Forums";
import {Buzz} from "./layouts/Buzz";
import {Groups} from "./layouts/Groups";
import {Welcome} from './layouts/Welcome';
import {Discover} from './layouts/Discover';
import {Patron} from './layouts/Patron';
import {Activities} from "./layouts/Activities";
import {Chats} from "./layouts/Chats";
import {PageLayout} from "./layouts/PageLayout";
import {AppLayout} from "./layouts/AppLayout";
import {Terms, Guidelines} from './layouts/Terms-Guidelines';
import {PrivacyPolicy} from './layouts/PrivacyPolicy';
import {RegistrationReq} from './layouts/RegistrationReq';
import ErrorPage from "./layouts/error-page";
import { createBrowserRouter, RouterProvider, Navigate } from "react-router-dom";
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import "react-datepicker/dist/react-datepicker.css";
import { AuthContext } from "./context/AuthContext";
import { useAuth } from "./hooks/useAuth";
import { PayPalScriptProvider } from '@paypal/react-paypal-js';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Welcome />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/our-story",
    element: <OurStory />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/signup",
    element: <Signup/>,
    errorElement: <ErrorPage />,
  },
  {
    path: "/login",
    element: <Login/>,
    errorElement: <ErrorPage />,
  },
  { path: "/text",
    element: <PageLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "terms",
        element: <Terms/>,
      },
      {
        path: "guidelines",
        element: <Guidelines/>,
      },
      {
        path: "privacyPolicy",
        element: <PrivacyPolicy/>,
      },
      {
        path: "registrationReq",
        element: <RegistrationReq/>
      },
      {
        path: "workWithUs",
        element: <Patron />,
      }
    ],
  },
  { path: "/app",
    element: <AppLayout showNavBtn={true} loggedIn={true}/>,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "checkout",
        element: <Checkout/>
      },
      {
        path: "profile",
        element: <Profile/>,
        children: [
          { index:true, element:  <Timeline/> },
          { path: "info", element: <Info/> },
          { path: "connections", element: <Connections/> },
          { path: "circles", element: <Circles/> },
          { path: "reviews", element: <Reviews label="No review"/> },
          { path: "forums", element: <Reviews label="No forums"/> },
          { path: "groups", element: <Reviews label="No groups"/> },
          { path: "album", element: <Reviews label="No albums"/> },
          { path: "docs", element: <Reviews label="No documents"/> },
        ]
      },
      {
        path: "settings",
        element: <Settings/>,
      },
      {
        path: "forums",
        element: <Forums/>,
      },
      {
        path: "buzz",
        element: <Buzz/>,
        children: [
          { index:true, element:  <Timeline/> },
          { path: "connectedUsers", element: <Reviews label="No connected users"/> },
          { path: "members", element: <Reviews label="No member"/> }
        ]
      },
      {
        path: "groups",
        element: <Groups/>,
      },
      {
        path: "members",
        element: <Discover/>,
      },
      {
        path: "activities",
        element: <Activities/>,
      },
      {
        path: "chats",
        element: <Chats/>,
      },
      {
        path: "subscription",
        element: <Subscription/>,
      },
    ],
  }
]);

const initialOptions = {
    clientId: "test",
    currency: "USD",
    intent: "capture",
};

function App() {
  const { user, login, logout, setUser } = useAuth();

  return  <PayPalScriptProvider options={{ "client-id": "sb", "components":"buttons" }}>
            <AuthContext.Provider value={{ user, setUser }}>
              <RouterProvider router={router} />
            </AuthContext.Provider>
          </PayPalScriptProvider>
}

export default App;