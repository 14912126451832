import React, {useState,useEffect,useRef} from 'react';
import {Container,Stack, DropdownButton, Dropdown, Nav, Row, Col, Image, Badge, Card, Button, Form, InputGroup, Overlay, OverlayTrigger, Tooltip} from 'react-bootstrap';

export const MyCheckBox = ({cbState=false, setCbState}) => {
    return  <div className="flex-shrink-0" 
                onClick={() => setCbState(!cbState)} 
                style={{width:30,height:30,borderRadius:15,textAlign:'center',
                        backgroundColor:(cbState===true?'#0ac5bf':'transparent'),
                        color:'white',fontSize:28,border:'2px solid #0ac5bf',
                        display:'flex',alignItems:'center',justifyContent:'center',
                        cursor:'pointer',marginRight:5}}>
                {cbState&&<i className="bi bi-check"></i>}
            </div>
}

export const MyRadioInputs = ({label="Gender at birth", keyArr=['male','female', 'other'], valueArr=[false,false,false], onSelect}) => {
    const update = (index) => {
        let arr = valueArr;
        for (let i = 0; i < arr.length; i++){ 
            arr[i] = false;
        }
        arr[index] = true;
        onSelect([...arr])
    }

    return  <>
            <label style={{color:'#888'}}>{label}</label>
            <Row className="d-flex align-items-center mt-3 mb-3" style={{width:'100%'}}>
                {keyArr.map((value, index) => <Col xs={4} md={3} className="d-flex align-items-center">
                    <div className="flex-shrink-0" 
                        onClick={() => update(index)} 
                        style={{width:20,height:20,borderRadius:10,textAlign:'center',
                                backgroundColor:(valueArr[index]===true?'#0ac5bf':'transparent'),
                                color:'white',fontSize:9,border:'3px solid #0ac5bf',
                                display:'flex',alignItems:'center',justifyContent:'center',
                                cursor:'pointer',marginRight:5}}>
                        {valueArr[index]&&<i className="bi bi-circle-fill"></i>}
                    </div>
                    <span style={{color:'white'}}>{value}</span>
                </Col>)}
            </Row>
            </>
}

export const MiniAvatar = ({imageUrl, onClick}) => {
    return  <div onClick={onClick} className="avatar-container d-flex align-items-center justify-content-center flex-shrink-0" style={{cursor:'pointer', width: '40px', height: '40px', backgroundColor: '#b5edeb', borderRadius: '50%', position: 'relative'}}>
                {!imageUrl && <i className="bi bi-person-fill" style={{fontSize:22, color:'#0ac5bf'}}></i>}
                {imageUrl && <Image src={imageUrl} roundedCircle style={{width: '100%', height: '100%', position: 'absolute', top: '0', left: '0'}} />}
            </div>
}

export const Avatar = ({imageUrl}) => {
    return  <div className="avatar-container d-flex align-items-center justify-content-center flex-shrink-0" style={{width: '60px', height: '60px', backgroundColor: '#b5edeb', borderRadius: '50%', position: 'relative'}}>
                {!imageUrl && <i className="bi bi-person-fill" style={{fontSize:28, color:'#0ac5bf'}}></i>}
                {imageUrl && <Image src={imageUrl} roundedCircle style={{width: '100%', height: '100%', position: 'absolute', top: '0', left: '0'}} />}
            </div>
}

export const CardButton  = ({className="bi-hand-thumbs-up",label="", onPickReaction}) => {
	return <Button className="d-flex" style={{borderWidth:0,
                            color:'#0ac5bf',
                            backgroundColor:'transparent',
                            fontSize: '0.7rem'}}>
                <i onClick={onPickReaction} className={`bi ${className}`}></i>
                <span style={{marginLeft:3}}>{label}</span>
            </Button>
}



export const PostCard = ({post}) => {
    const [isLiked, setIsLiked] = useState(false);
    const [reactions, setReactions] = useState(post.likes);
    const [comments, setComments] = useState(post.comments);
    const [shares, setShares] = useState(post.shares);
    const [showReactionlist, setShowReactionlist] = useState(false);
    const reactionArr = [
        { emoji: '👍', label: 'Like' },
        { emoji: '❤️', label: 'Love' },
        { emoji: '😂', label: 'Haha' },
        { emoji: '😮', label: 'Wow' },
        { emoji: '😢', label: 'Sad' },
        { emoji: '😡', label: 'Angry'}
    ];

    const onPickReaction = () => {
        // setIsLiked(!isLiked);
        // setLikes(isLiked ? likes - 1 : likes + 1);
        setShowReactionlist(!showReactionlist)
    }

    const target = useRef(null);

    return  <Card className="mb-1">
                <Card.Header className="d-flex align-items-center" style={{backgroundColor:'transparent',borderBottomWidth:0}}>
                    <Avatar imageUrl={post.user.avatar} />
                    <div style={{marginLeft:5}}>
                        <div style={{fontStyle:16,fontWeight:'bold'}}>{post.user.username}</div>
                        <div className="text-muted" style={{fontSize:11}}>
                            <span>{post.createdAt}</span>
                            <span style={{marginLeft:4}}><i className="bi bi-globe-europe-africa"></i></span>
                        </div>
                    </div>
                </Card.Header>
                <Card.Body className="card-body pr-3 pl-3 pt-0 pb-0">
                    <Card.Text class="pt-0 pb-0 mb-1" style={{fontSize:13,paddingLeft:10,paddingRight:10}}>{post.content}</Card.Text>
                    {post.image &&<Container class="pt-0 pb-0 mt-0 mb-0" style={{paddingLeft:10,paddingRight:10}} className="d-flex align-items-center justify-content-center" fluid>
                        <Image src={post.image} fluid />
                    </Container>}
                </Card.Body>
                <Card.Footer className="d-flex" style={{backgroundColor:'transparent',borderTopWidth:0}}>
                    <>  
                        <CardButton ref={target} className="bi-emoji-smile-fill" label={`${reactions} reactions`} onPickReaction={onPickReaction}/>
                        {showReactionlist&&<div style={{border:'1px solid #0ac5bf',padding:'5px 5px',backgroundColor:'white',width:140,position:'absolute',zIndex:88888888,marginTop:30, borderRadius:8 }}>
                            <>{reactionArr.map((value, index) => (<div style={{cursor:'pointer'}}>
                                <p className="m-2 p-0" style={{color:'#0ac5bf',fontWeight:'bold',fontSize:13}}>{value.emoji} {value.label}</p>
                            </div>))}</>
                        </div>}
                    </>
                    <CardButton className="bi-chat-left-text" label={`${comments} Comments`}/>
                    <CardButton className="bi-flag" label="Report"/>
                </Card.Footer>
            </Card>
}

export const CreatePostInput = ({CurrentUserData={username:'eli'}, disablePrivacy}) => {
    const [publik, setPublik] = useState(true);

    return <Card className="mb-1">
                <Card.Body className="p-0">
                    <InputGroup style={{borderRadius: 0}}>
                        <div class="d-flex" style={{width:55,height:55,justifyContent:'center',alignItems:'center'}}>
                            <MiniAvatar />
                        </div>
                        <Form.Control 	as="textarea"
                                        placeholder={`What's on your mind ${CurrentUserData?.username}?`}
                                        aria-label="Username"
                                        style={{ resize: 'none', borderWidth:0, fontSize:13 }}
                                        aria-describedby="basic-addon1"/>
                        <DropdownButton variant="outline-none" className="privacyBtn" title={publik?<i class="bi bi-globe-europe-africa" 
                                                                            style={{color:'#0AC5BF'}}></i>
                                                                        :<i class="bi bi-lock"
                                                                            style={{color:'#0AC5BF'}}></i>}
                                        id="input-group-dropdown-1"
                                        disabled={disablePrivacy}
                                        style={{color:'#0AC5BF',borderWidth:0,borderColor:'#eee',backgroundColor:'transparent'}}>
                            <Dropdown.Item onClick={() => setPublik(true)}><i class="bi bi-globe-europe-africa"></i> All member</Dropdown.Item>
                            <Dropdown.Item onClick={() => setPublik(true)}><i class="bi bi-people-fill"></i> All member</Dropdown.Item>
                            <Dropdown.Item onClick={() => setPublik(false)}><i class="bi bi-lock"></i> Only me</Dropdown.Item>
                        </DropdownButton>
                        <Button variant="outline-secondary" style={{borderWidth:0}}>
                            <i className="bi bi-paperclip" style={{fontSize:16}}></i>
                        </Button>
                        <Button variant="outline-secondary" style={{borderWidth:0,fontSize:16}}>
                            Post
                        </Button>
                    </InputGroup>
                </Card.Body>
            </Card>
}