import React from 'react';
import { Container, Card, Col, Row } from 'react-bootstrap';
import {PostCard, MiniAvatar, Avatar, CardButton} from '../../components/ModularComponents';
import '../../css/profile.css';

export const Connections = () => {
    return (
        <Container>
            <p>No Connections</p>
        </Container>
    );
}

