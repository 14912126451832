import React, {useState, useEffect, useRef, useContext, useCallback} from 'react';
import {Container,Stack} from 'react-bootstrap';
import { Outlet } from "react-router-dom";
import {TransparentNavBar, NavBar, BlackNavBar} from '../components/NavBar';
import {Footer} from '../components/Footer';

export const PageLayout = ({showNavBtn, loggedIn}) => {

	return 	<div style={{display: 'flex', flexDirection: 'column', height: '100%',fontFamily:'Merriweather'}}>
				<BlackNavBar loggedIn={loggedIn} showNavBtn={showNavBtn}/>
				<Container className="p-0" fluid style={{flex:'1', width:'100%',height:'100%',position: 'relative',marginTop:75}}>
					<Outlet/>
				</Container>
				<Footer/>
			</div>
}