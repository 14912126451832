import React, {useState, useEffect, useRef, useContext, useCallback} from 'react';
import {Container,Stack} from 'react-bootstrap';
import '../css/linkEl.css';

export const RegistrationReq = () => {

	return 	<>
				<Container>
					<div className="pt-4">
						<h2 style={{fontStyle:'italic',fontWeight:'bold'}}>Registration requirements</h2>
						<p>Shap Social Club encourages the redefinition of norms around pleasure, sexuality, and desire while fostering inclusivity and acceptance.</p>
						<p><strong>A Social Club for the Adventurous:<br/></strong></p>
						<p><em><strong>The Curious</strong></em>: Those who have a strong desire to know or learn something. They are driven by inquisitiveness and are constantly seeking out new information and experiences.</p>
						<p><em><strong>The Experiential</strong></em>: The ones who prioritize direct experience over theoretical knowledge. They learn by doing and often seek hands-on activities, adventures, and practical engagement with the world. Their focus is on gaining insights through personal involvement and sensory experiences.</p>
						<p><em><strong>The Explorers</strong></em>: Those who actively seek out new places, ideas, or experiences. They often push the boundaries of what is known. Explorers are driven by a desire to uncover the unknown and expand their horizons.</p>
						<p><strong>Eligibility Criteria:</strong></p>
						<p>To fulfill the objectives of our private membership social club, applicants must meet the following eligibility criteria:</p><p><strong>Age Requirement</strong>: Applicants must be over 25 years old to register.</p>
						<p><strong>Consent to Sexual Freedom</strong>: If you believe that expressions of sexual freedom may offend you, we kindly ask that you refrain from registering on this site. Our goal is to provide a space where liberated adults can explore and experiment with their sexuality without judgment or discrimination.</p>
						<p><strong>Self-Reflection</strong>: We invite you to reflect on your motivations for joining our social club. We encourage you to become a member with the intention of enhancing your life positively, rather than using our club as a means to engage in self-destructive or harmful behaviors.&nbsp;</p>
						<p><strong>Human-First Approach:</strong> You will prioritize approaching and interacting with other members first and foremost as fellow human beings. You will consciously set aside any preconceived notions or stereotypes, choosing instead to see each individual as a complex and unique person deserving of respect and understanding.&nbsp;</p>
						<p><strong>Adhere to the <a href="https://www.theharveyinstitute.com/six-principles-of-sexual-health">six principles of sexual health</a>:</strong> Consent, Non-exploitation, Protection from STIs and unintended pregnancy, Honesty, Shared values and Mutual Pleasure.</p>
						<p><strong>Shared Values</strong>: To ensure a harmonious social club, you must align with our social club’s values. These <a href="https://shap.buzz/our-story/">values</a> include:</p>
						<p><strong>C</strong>-Consent, Communication &amp; Consideration<br/><strong>L</strong>-Look out for each other<br/><strong>O</strong>-Open-Minded<br/><strong>S</strong>-Safety<br/><strong>E</strong>-Empathy<br/><strong>R</strong>-Respect<br/><strong>A</strong>-Authentic<br/><strong>F</strong>-Feedback</p>
						<p>Please do not register to become a member unless and until you satisfy the Eligibility Criteria.</p>
						<p>To register to become a member, please complete the <a href="https://shap.buzz/register/sapphire/">registration form.</a></p>
					</div>
				</Container>
				<div className="pt-4 mb-0 pb-4" style={{color:'white', backgroundColor:'black'}}>
					<Container>
						<h2 style={{fontStyle:'italic',fontWeight:'bold',color:'#fed700'}}>Your Membership Unlocks a World of Possibilities</h2>
						<p>Shap Social Club encourages the redefinition of norms around pleasure, sexuality, and desire while fostering inclusivity and acceptance.</p>
						<p><strong>A Social Club for the Adventurous:<br/></strong></p>
						<p><em><strong>The Curious</strong></em>: Those who have a strong desire to know or learn something. They are driven by inquisitiveness and are constantly seeking out new information and experiences.</p>
						<p><em><strong>The Experiential</strong></em>: The ones who prioritize direct experience over theoretical knowledge. They learn by doing and often seek hands-on activities, adventures, and practical engagement with the world. Their focus is on gaining insights through personal involvement and sensory experiences.</p>
						<p><em><strong>The Explorers</strong></em>: Those who actively seek out new places, ideas, or experiences. They often push the boundaries of what is known. Explorers are driven by a desire to uncover the unknown and expand their horizons.</p>
						<p><strong>Eligibility Criteria:</strong></p><p>To fulfill the objectives of our private membership social club, applicants must meet the following eligibility criteria:</p>
						<p><strong>Age Requirement</strong>: Applicants must be over 25 years old to register.</p>
						<p><strong>Consent to Sexual Freedom</strong>: If you believe that expressions of sexual freedom may offend you, we kindly ask that you refrain from registering on this site. Our goal is to provide a space where liberated adults can explore and experiment with their sexuality without judgment or discrimination.</p>
						<p><strong>Self-Reflection</strong>: We invite you to reflect on your motivations for joining our social club. We encourage you to become a member with the intention of enhancing your life positively, rather than using our club as a means to engage in self-destructive or harmful behaviors.&nbsp;</p>
						<p><strong>Human-First Approach:</strong> You will prioritize approaching and interacting with other members first and foremost as fellow human beings. You will consciously set aside any preconceived notions or stereotypes, choosing instead to see each individual as a complex and unique person deserving of respect and understanding.&nbsp;</p>
						<p><strong>Adhere to the <a href="https://www.theharveyinstitute.com/six-principles-of-sexual-health">six principles of sexual health</a>:</strong> Consent, Non-exploitation, Protection from STIs and unintended pregnancy, Honesty, Shared values and Mutual Pleasure.</p>
						<p><strong>Shared Values</strong>: To ensure a harmonious social club, you must align with our social club’s values. These <a href="https://shap.buzz/our-story/">values</a> include:</p>
						<p><strong>C</strong>-Consent, Communication &amp; Consideration<br/><strong>L</strong>-Look out for each other<br/><strong>O</strong>-Open-Minded<br/><strong>S</strong>-Safety<br/><strong>E</strong>-Empathy<br/><strong>R</strong>-Respect<br/><strong>A</strong>-Authentic<br/><strong>F</strong>-Feedback</p>
						<p>Please do not register to become a member unless and until you satisfy the Eligibility Criteria.</p>
						<p>To register to become a member, please complete the <a href="https://shap.buzz/register/sapphire/">registration form.</a></p>
					</Container>
				</div>
			</>	
}